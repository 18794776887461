import React, { useEffect, useState } from 'react';
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const FinishSignIn = () => {
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  const trackLogin = () => {
    if (!localStorage.getItem('hasTrackedLogin') && window.gtag) {
      window.gtag('event', 'login', {
        event_category: 'User',
        event_label: 'Email Link Login',
        method: 'Email'
      });
      localStorage.setItem('hasTrackedLogin', 'true');
    }
  };

  useEffect(() => {
    async function completeSignIn() {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = window.localStorage.getItem('emailForSignIn');
        
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }

        try {
          await signInWithEmailLink(auth, email, window.location.href);
          trackLogin(); // Track successful email link login
          window.localStorage.removeItem('emailForSignIn');
          navigate('/');
        } catch (error) {
          console.error('Error signing in with email link:', error);
          setError('Error signing in. Please try again.');
        }
      }
      setIsProcessing(false);
    }

    completeSignIn();
  }, [auth, navigate]);

  if (isProcessing) {
    return <div>Completing sign in...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return null;
};
 
export default FinishSignIn;