import React, { useState, useEffect, useRef } from 'react';
import './AffiliateLanding.css';

const AffiliateChat = () => {
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    
    const conversationLoop = [
        { id: 1, type: 'ai', text: "Hi! How can I help you get started with our affiliate program? 👋", delay: 0 },
        { id: 2, type: 'user', text: "I'm interested but what are the payment terms?", delay: 2000 },
        { id: 3, type: 'ai', text: "Weekly payouts every Friday as long as you've earned $50 or more! We support PayPal, bank transfer, and crypto 💸", delay: 4000 },
        { id: 4, type: 'user', text: "That's quick! How long do referrals stay linked to my account?", delay: 6000 },
        { id: 5, type: 'ai', text: "Forever! You'll earn 50% of ALL their payments as long as they remain subscribed. It's truly passive income 🚀", delay: 8000 },
        { id: 6, type: 'user', text: "Awesome! Do you have marketing materials I can use?", delay: 10000 },
        { id: 7, type: 'ai', text: "Yes! You'll get instant access to proven landing pages, banners, and email templates. We're here to help you succeed! 📈", delay: 12000 }
    ];
  
    useEffect(() => {
        const addMessage = (message) => {
            setMessages(prev => [...prev, message]);
        };
  
        const startConversation = () => {
            setMessages([]);
            conversationLoop.forEach(message => {
                setTimeout(() => {
                    addMessage(message);
                }, message.delay);
            });
        };
  
        startConversation();
        const interval = setInterval(startConversation, 14000);
  
        return () => clearInterval(interval);
    }, []);
  
    return (
        <div className="chat-container">
            <div className="chat-header">
                <div className="chat-avatar">
                    <img src="profile2.png" alt="Affiliate Support" />
                </div>
                <div className="chat-status">
                    <div className="chat-name">Affiliate Support</div>
                    <div className="chat-indicator">Always here to help</div>
                </div>
            </div>
            
            <div className="chat-messages" ref={chatContainerRef}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`chat-message ${message.type === 'ai' ? 'ai' : 'user'}`}
                    >
                        {message.text}
                    </div>
                ))}
            </div>
        </div>
    );
};

const AffiliateTable = () => {
    return (
        <div className="affiliate-table">
            <table>
                <thead>
                    <tr>
                        <th className="gradient-text">Traffic Level</th>
                        <th>Monthly Visitors</th>
                        <th>Conversion Rate</th>
                        <th className="gradient-text">Monthly Income</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="level-col">Starting</td>
                        <td>1,000</td>
                        <td>2%</td>
                        <td className="income-col">$150</td>
                    </tr>
                    <tr>
                        <td className="level-col">Growing</td>
                        <td>5,000</td>
                        <td>2%</td>
                        <td className="income-col">$750</td>
                    </tr>
                    <tr>
                        <td className="level-col">Professional</td>
                        <td>20,000</td>
                        <td>2%</td>
                        <td className="income-col">$3000</td>
                    </tr>
                    <tr>
                        <td className="level-col">Expert</td>
                        <td>50,000+</td>
                        <td>2%</td>
                        <td className="income-col">$7500+</td>
                    </tr>
                </tbody>
            </table>
            <div className="disclaimer">
                *Based on $15 average monthly customer value with 50% commission rate
            </div>
        </div>
    );
};
const AnimatedStats = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const stats = [
    { number: "$800+", text: "Average monthly earnings" },
    { number: "100+", text: "Active affiliates" },
    { number: "50%", text: "Commission rate" }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
        setIsAnimating(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats">
      <div className="stats-desktop">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <div className="stat-number">{stat.number}</div>
            <div className="stat-text">{stat.text}</div>
          </div>
        ))}
      </div>

      <div className="stats-mobile">
        <div className="stat">
          <div className={`stat-number ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].number}
          </div>
          <div className={`stat-text ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].text}
          </div>
        </div>
      </div>
    </div>
  );
};


const SetupCards = () => {
    return (
        <div className="setup-cards">
            <div className="setup-card">
                <div className="setup-icon">1</div>
                <div className="setup-content">
                    <h3>Quick Registration</h3>
                    <p>Sign up in under 2 minutes with just your email and payment details. No complex verification needed.</p>
                </div>
            </div>
            
            <div className="setup-card">
                <div className="setup-icon">2</div>
                <div className="setup-content">
                    <h3>Get Your Links</h3>
                    <p>Instantly receive your unique affiliate links and tracking codes. Choose from multiple landing pages and offers.</p>
                </div>
            </div>
            
            <div className="setup-card">
                <div className="setup-icon">3</div>
                <div className="setup-content">
                    <h3>Start Earning</h3>
                    <p>Share your links and watch commissions roll in. Get weekly payouts with no minimum threshold.</p>
                </div>
            </div>
        </div>
    );
};

const AffiliateLanding = () => {
  const featuresRef = useRef(null);
  const earnRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <nav className={`nav ${isScrolled ? 'nav-scrolled' : ''}`}>
        <div className="nav-left">
          <div className="nav-links">
            <a href="#features" onClick={(e) => {
              e.preventDefault();
              scrollToSection(featuresRef);
            }}>Benefits</a>
            <a href="#earn" onClick={(e) => {
              e.preventDefault();
              scrollToSection(earnRef);
            }}>How to Earn</a>
            <a href="mailto:affiliates@lollipop.chat">Support</a>
          </div>
        </div>
        <a className="start-button" style={{textDecoration:"none"}} target="_blank" href="https://affiliate.lollipop.chat">
          Become an Affiliate
        </a>
      </nav>

      <div className="landing-container">
        <div className="hero-section">
          <div className="background">
            <video src="affiliatevideo.mp4" alt="Background" autoPlay muted loop playsinline />
            <div className="overlay"></div>
          </div>

          <div className="main-content">
            <h1 className="main-heading">Earn <b>Big</b> With AI Girls</h1>
            <p className="sub-heading">50% commission on all referrals. No caps. No limits.</p>
            <a 
              className="start-button start-button-main" 
              style={{width: "250px", height: "60px", fontSize: "20px", textDecoration:"none"}}
              target="_blank" href="https://affiliate.lollipop.chat"
            >
              Start Earning Now
            </a>
          </div>

          <AnimatedStats />
        </div>

        <div className="benefits-section" ref={featuresRef} id="features">
          <h2 className="section-title">Why Partner With Us?</h2>

          <div className="benefit-row">
            <div className="benefit-image">
              <SetupCards />
            </div>
            <div className="benefit-content">
              <h3>Extremely simple setup</h3>
              <p>Sign up in 2 minutes and link the account you want to get paid to. Promote however you see fit, but we include a library of assets you can use!</p>
            </div>
          </div>

          <div className="benefit-row reverse">
            <div className="benefit-image">
            <AffiliateTable />
            </div>
            <div className="benefit-content">
              <h3>High commission and a product that sells</h3>
              <p>AI girlfriend apps are taking over the world, and we are leading it. <br/>We pay 50% from every referral. Since Lollipop uses a subscription model, you'll keep earning passive income!</p>
            </div>
          </div>

          <div className="benefit-row">
            <div className="benefit-image">
                <AffiliateChat />
            </div>
            <div className="benefit-content">
              <h3>Dedicated Affiliate Support</h3>
              <p>Work directly with our affiliate managers who are invested in your success. Get personalized strategies, rapid responses, and regular optimization tips to boost your earnings.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AffiliateLanding;