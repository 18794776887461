import React, { useState, useEffect } from 'react';
import { 
  getAuth, 
  sendSignInLinkToEmail,
  signInWithPopup, 
  GoogleAuthProvider,
  TwitterAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
import './SignupModal.css';

const SignupModal = ({ onClose, preloadedImage }) => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [socialLoading, setSocialLoading] = useState('');
  const [mode, setMode] = useState('signup');
  
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const facebookProvider = new FacebookAuthProvider();

  const actionCodeSettings = {
    url: window.location.origin + '/finish-signin',
    handleCodeInApp: true,
  };

  const trackLogin = (method) => {
    if (!localStorage.getItem('hasTrackedLogin') && window.gtag) {
      window.gtag('event', 'login', {
        event_category: 'User',
        event_label: `${method} Login`,
        method: method
      });
      localStorage.setItem('hasTrackedLogin', 'true');
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (isLoading || socialLoading) return;
    setError('');
    setSuccessMessage('');
    setIsLoading(true);
    
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      setSuccessMessage('Sign-in link sent! Check your email to complete sign-in.');
    } catch (error) {
      console.error('Auth error:', error.code, error.message);
      setError('Could not send sign-in link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSocialSignIn = async (provider, providerName) => {
    if (isLoading || socialLoading) return;
    setError('');
    setSuccessMessage('');
    setSocialLoading(providerName);
  
    try {
      const result = await signInWithPopup(auth, provider);
      if (result?.user) {
        trackLogin(providerName); // Track successful login
        onClose();
      }
    } catch (error) {
      console.error('Social sign-in error:', error.code, error.message);
      
      switch (error.code) {
        case 'auth/account-exists-with-different-credential':
          setError(`This email is already registered with a different sign-in method.`);
          break;
          
        case 'auth/popup-blocked':
          setError('Popup was blocked. Please allow popups and try again.');
          break;
          
        case 'auth/cancelled-popup-request':
        case 'auth/popup-closed-by-user':
          console.log('User cancelled sign in');
          setError('');
          break;
          
        default:
          setError(`Could not sign in with ${providerName}. Please try another method.`);
      }
    } finally {
      setSocialLoading('');
    }
  };

  const renderSocialButtons = () => {
    if (socialLoading) {
      return (
        <div className="loading-container">
          <span className="loginloader"></span>
          <p className="loading-text">
            Waiting for response from {socialLoading}...
          </p>
        </div>
      );
    }

    return (
      <div className="social-buttons">
        <button 
          className="social-button google"
          onClick={() => handleSocialSignIn(googleProvider, 'Google')}
          disabled={isLoading}
        >
          <img src="https://static.cdnlogo.com/logos/g/38/google-icon.svg" alt="Google" />
          Google
        </button>
        
        <div className="social-buttons-group">
          <button 
            className="social-button x"
            onClick={() => handleSocialSignIn(twitterProvider, 'Twitter')}
            disabled={isLoading}
          >
            <img src="https://www.cdnlogo.com/logos/t/96/twitter-icon.svg" alt="X" />
            Twitter/X
          </button>
          <button 
            className="social-button facebook"
            onClick={() => handleSocialSignIn(facebookProvider, 'Facebook')}
            disabled={isLoading}
          >
            <img src="https://www.cdnlogo.com/logos/f/84/facebook.svg" alt="Facebook" />
            Facebook
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="signup-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-image-section">
          {preloadedImage ? (
            <img src={preloadedImage} alt="Welcome" className="modal-image" />
          ) : (
            <div className="modal-image-placeholder">
              <img src="anna2.png" alt="Welcome" className="modal-image" />
            </div>
          )}
        </div>
        <div className="modal-content-section">
          <button className="close-button" onClick={onClose}>×</button>
          
          <h2 className="modal-title">
            {mode === 'signup' ? 'Create Account' : 'Sign In'}
          </h2>
          <div style={{marginTop:"-20px", marginBottom:"15px", opacity:0.7}}>Get free tokens!</div>

          {error && <div className="error-message">{error}</div>}
          {successMessage && <div className="success-message">{successMessage}</div>}

          <form onSubmit={handleEmailSubmit} className="signup-form">
            <div className="input-group">
              <input
                type="email"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="modal-input"
                disabled={isLoading || socialLoading}
              />
            </div>

            <button 
              type="submit" 
              className={`create-account-button ${isLoading ? 'loading' : ''}`}
              disabled={isLoading || socialLoading}
            >
              {isLoading ? 'Please wait...' : 
               mode === 'signup' ? 'Create Free Account' : 'Sign In'}
            </button>
          </form>

          <div className="divider">
            <span>or continue with</span>
          </div>

          {renderSocialButtons()}

          <p className="login-link">
            {mode === 'signup' ? (
              <>Already have an account? <a href="#" onClick={(e) => {
                e.preventDefault();
                if (!isLoading && !socialLoading) {
                  setMode('signin');
                  setError('');
                  setSuccessMessage('');
                }
              }}>Sign in</a></>
            ) : (
              <>Need an account? <a href="#" onClick={(e) => {
                e.preventDefault();
                if (!isLoading && !socialLoading) {
                  setMode('signup');
                  setError('');
                  setSuccessMessage('');
                }
              }}>Sign up</a></>
            )}
          </p>

          {mode === 'signup' && (
            <p className="terms">
              By signing up, you agree you are at least 18 years old and you accept the <a href="https://lollipop.chat/tos">Terms of Service</a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignupModal;