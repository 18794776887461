import React, { useState, useEffect } from 'react';
import './App.css';
import OpenAI from 'openai';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from './firebase'; // Import auth
import { signOut } from "firebase/auth";
import GalleryModal from './GalleryModalNew';
import { set } from 'react-ga';
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { CircularProgressbar, buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import sponsordata from './sponsordata.json';


function App() {
  const [imageUrl, setImageUrl] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credits, setCredits] = useState(null);

  const [user, setUser] = useState(null);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  const openGallery = () => setIsGalleryOpen(true);
  const closeGallery = () => setIsGalleryOpen(false);

  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  const sweety = (message) => {
    Swal.fire({
      text: message,
      background: "rgba(255, 0, 189, 0.5)",
      position: 'bottom',
      showConfirmButton: false,
      color:"white",
      backdrop:false,
      timer: 5000,
      timerProgressBar: true,
      fontSize:"12px"
    })
  }

  

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser(user);
    });
  }, []);

  const fetchCredits = async (userEmail) => {
    try {
      const response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/read-credits', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (data.status === 200 && data.error_code === 0) {
        // Successfully retrieved token balance
        console.log('Token Balance:', data.token_balance);
        setCredits(data.token_balance);
      } else {
        console.error('Error fetching credits:', data.message);
        return null; // or handle error appropriately
      }
    } catch (error) {
      console.error('There was a problem fetching user credits:', error);
    }
  };

  useEffect(() => {
    if(user){
      if(localStorage.getItem('tos') !== "true"){
        setIsModal2Open(true)
      }
    fetchCredits(user.email);
    
  }
  }, [user]);

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      
      // Track login event if not already tracked this session
      if (!localStorage.getItem('hasTrackedLogin') && window.gtag) {
        window.gtag('event', 'login', {
          event_category: 'User',
          event_label: 'Google Login',
          method: 'Google'
        });
        localStorage.setItem('hasTrackedLogin', 'true');
      }
  
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };


  const signOutUser = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

// Assuming you have set up Stripe.js and Stripe Elements

  const saveImageUrlToLocalStorage = (url) => {
    const savedImages = JSON.parse(localStorage.getItem('generatedImages')) || [];
    savedImages.push(url);
    localStorage.setItem('generatedImages', JSON.stringify(savedImages));
  };

  const randomize = () => {
    //set searchbar value from array
    const prompts = ["21 year old swedish girl, blonde hair, gray eyes, showing tits, dark theme", "20 year old asian girl, blue swimsuit, on the beach, showing butt, from behind", "30 year old italian girl, dark theme, showing boobs, big boobs, naked, close-up portrait", "Sexy 45 year old mom, blonde, naked, in the park, posing, sunlight", "18 year old black girl, big ass, big boobs, red underwear, showing ass, posing"]
    const random = Math.floor(Math.random() * prompts.length);
    document.querySelector('.searchbar').value = prompts[random];
  }

  const [generateData, setGenerateData] = useState("")

  const generateImage = async () => {
    if(!document.querySelector('.searchbar').value || document.querySelector('.searchbar').value.length == 0){
      sweety("Please write a prompt to describe the girl.")
      return;
    }
    if (!user) {
      console.log("User not signed in.")
      signInWithGoogle();
      return;
    }
    if(credits == 0){
      setIsModal3Open(true);
      return;
    }
    console.log("Generating image.")
    setLoading(true);
    setIsModalOpen(true);

    sweety("Generating your photo. Give it a few seconds.")
    const userQuery = document.querySelector('.searchbar').value;
    try {
        // Step 1: Call the generate-image endpoint
        // Include the user's OAuth token in the request headers
        const authToken = await auth.currentUser.getIdToken();

        const generate_response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/generate-image?prompt=' + userQuery, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authToken, // Include the token in the Authorization header
            },
        });
        const generate_data = await generate_response.json();
        
        if (generate_data.error_code === 41) {
          // Specific error handling
          alert("Your prompt does not follow the guidelines you agreed to. Please be careful."); // Display the error message to the user
          return; // Stop further processing
        }
        setGenerateData(generate_data)
        setImageUrl(generate_data.image_url); // Use the 'upscaled_image_url' from the upscale response
        sweety("Say hi, she's all yours. ❤️")
        fetchCredits(user.email);

        {/*if (generate_data.status === 200 && generate_data.error_code === 0) {
            // Step 2: Call the upscale-image endpoint with the returned URL and inf_id
            const upscale_response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/upscale-image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url: generate_data.image_url, inf_id: generate_data.inf_id }),
            });

            const upscale_data = await upscale_response.json();
            if (upscale_data.status === 200 && upscale_data.error_code === 0) {
                setImageUrl(upscale_data.upscaled_image_url); // Use the 'upscaled_image_url' from the upscale response
                setIsModalOpen(true);
                saveImageUrlToLocalStorage(upscale_data.upscaled_image_url);
                fetchCredits(user.email);

            } else {
                console.error('Error upscaling image:', upscale_data.message);
            }
        } else {
            console.error('Error generating image:', generate_data.message);
        }
        */}
    } catch (error) {
        console.error('Error:', error);
    } finally {
        setLoading(false);
    }
};

  const [upscaledImageUrl, setUpscaledImageUrl] = useState('');

  const upScale = async () => {
    setUpscaledImageUrl("")
    console.log(generateData)
    setIsModalOpen(false);
    setIsUpscaleModalOpen(true)
    setLoading(true);
    const authToken = await auth.currentUser.getIdToken();

        const generate_response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/upscale?image_url=' + generateData.image_url + '&inf_id=' + generateData.inf_id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authToken, // Include the token in the Authorization header
            },
        });
        const generate_data = await generate_response.json();
        setUpscaledImageUrl(generate_data.image_url); // Use the 'upscaled_image_url' from the upscale response
        console.log(generate_data.image_url)
        fetchCredits(user.email);
  }

  


  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [progress, setProgress] = useState(0);


  useEffect(() => {
    setProgress(0);
    if (loading) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + 100 / 20;
          if (newProgress >= 100) {
            clearInterval(interval);
            setLoading(false); // Stop loading once 100% is reached
            return 100;
          }
          return newProgress;
        });
      }, 1000); // Update progress every second

      return () => clearInterval(interval);
    }
  }, [loading]);

  const Modal = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          {loading ?
          <div className="loading-container">
            <div className="generating">Creating your girl.</div>
            <CircularProgressbar
              value={progress}
              text={`${Math.round(progress)}%`}
              styles={buildStyles({
                pathColor: `rgb(255, 0, 247, 0.8)`,
                textColor: `rgb(255, 0, 247, 0.8)`,
                trailColor: '#d6d6d6', // Light grey for the trail, modify as needed
              })} 
            />
          <div className="cancelButton" onClick={onClose}>Cancel</div>
        </div>
          :
          <div className="image-container">
            <img className="theporn" src={url} alt="AI Girl NSFW" />
            <a href={url} download="image.png" className="download-button">
              <i className="download-icon"></i> {/* Icon placeholder */}
            </a>
            {/*<button className="improve" onClick={()=>upScale()}>Improve photo</button>*/}
          </div>
          }
        </div>
      </div>
    );
  };

  const [isUpscaleModalOpen, setIsUpscaleModalOpen] = useState(false);


  const UpscaleModal = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          {loading ?
          <div className="loading-container">
            <div className="generating">Improving your girl.</div>
            <CircularProgressbar
              value={progress}
              text={`${Math.round(progress)}%`}
              styles={buildStyles({
                pathColor: `rgb(255, 0, 247, 0.8)`,
                textColor: `rgb(255, 0, 247, 0.8)`,
                trailColor: '#d6d6d6', // Light grey for the trail, modify as needed
              })} 
            />
          <div className="cancelButton" onClick={onClose}>Cancel</div>
        </div>
          :
          <div className="image-container">
            <img className="theporn" src={url} alt="AI Girl NSFW" />
            <a href={url} download="image.png" className="download-button">
              <i className="download-icon"></i>
            </a>
          </div>
          }
        </div>
      </div>
    );
  };


  const [isModal3Open, setIsModal3Open] = useState(false);

  const closeModal3 = () => {
    setIsModal3Open(false);
  };

  const Modal3old = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="buyCredits">
          <img style={{maxWidth:"100%"}} src="price.png" />
          <a className="link credits" href={"https://buy.stripe.com/aEUcNT4HGe1w37i288?prefilled_email=" + user?.email}><div style={{fontSize:"16px"}}>Add credits</div><div className="stripesecure">Secure payment with stripe</div></a>
          <div className="disclaimer">Important: when making the payment, use the same email address as on lollipop.chat.</div>

          </div>
        </div>
      </div>
    );
  };

  const [isModal4Open, setIsModal4Open] = useState(false);

  const closeModal4 = () => {
    setIsModal4Open(false);
  };

    const Modal4 = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal howtomodal" onClick={e => e.stopPropagation()}>
          <div>
            <div className="howtoexampletitle">Amazing prompt</div>
            <div className="howtoexample">21 year old swedish girl, blonde hair, gray eyes, showing tits, dark theme</div>
            <div className="howtoexampleexplainer">Describes the girl, describes how you want to see her. Includes details for style of the photograph.</div>
            
            <div className="howtoexampletitle">Good prompt</div>
            <div className="howtoexample">Cute girl naked</div>
            <div className="howtoexampleexplainer">It'll probably get you something decent.</div>
            
            <div className="howtoexampletitle">Bad prompt</div>
            <div className="howtoexample">2 girls, face covered in cum, big cock</div>
            <div className="howtoexampleexplainer">Lollipop is amazing at photorealistic and stunning girls. Lollipop is not great at generating custom porn scenarios yet.</div>
            
            <button className="howtogotit" onClick={onClose}>Got it, lets play!</button>
          </div>
        </div>
      </div>
    );
  };

  const Modal3 = ({ url, onClose }) => {
    return (
      <div className="backdrop" onClick={onClose}>
        <div className="modal modal3" onClick={e => e.stopPropagation()}>
          <div className="stripePayments"><img src="stripe.png" width="32px"></img> Secure payments with Stripe</div>
          <div className="payment-options">
            <div className="payment-card">
              <h3>Starter Fantasy</h3>
              <p>Love pussy, but want a sweet deal? <br/>This is for you!</p>
              <div className="payButtonWrapper"><a href={"https://buy.stripe.com/9AQbJPdec3mS8rC4gh?prefilled_email=" + user?.email} className="payButton"><span className="strike">€7.99</span> €2.99</a></div>
              <div className="tokenAmount">25 Tokens</div>
            </div>
            <div className="payment-card middle">
              <h3>Enhanced Ecstasy</h3>
              <p>You're an absolute legend, who needs more than the average guy.</p>
              <div className="payButtonWrapper"><a href={"https://buy.stripe.com/5kA8xD5LKf5A9vGeUW?prefilled_email=" + user?.email} className="payButton"><span className="strike">€19.99</span> €9.99</a></div>
              <div className="tokenAmount">100 Tokens</div>
            </div>
            <div className="payment-card">
              <h3>Limitless Lust</h3>
              <p>Johnny Sins himself is afraid of you. <br/> We are too. <b>You're a king.</b></p>
              <div className="payButtonWrapper"><a href={"https://buy.stripe.com/dR68xDdec2iObDO9AD?prefilled_email=" + user?.email} className="payButton">€49.99</a></div>
              <div className="tokenAmount">1000 Tokens</div>

            </div>
          </div>
          <div className="disclaimer">
            Important: when making the payment, use the same email address as on lollipop.chat.
          </div>
        </div>
      </div>
    );
  };
  

  const acceptTerms = () => {
    localStorage.setItem('tos', "true");
    setIsModal2Open(false);
  }

  const closeModal2 = () => {
    setIsModal2Open(false);
  };

  const [isModal2Open, setIsModal2Open] = useState(false);


  const Modal2 = ({ url, onClose }) => {
    return (
      <div className="backdrop backdrop2" >
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="termsmodal">
            I am 18 years of age or older, and <Link to="tos" style={{textDecoration:"none"}}>I accept the terms and conditions.</Link>
            <div className="acceptterms" onClick={() => acceptTerms()}>Yes, go to site!</div>
            </div>
        </div>
      </div>
    );
  };

  const ImageModal = ({ onClose }) => {
    return (
      <div className="image-modal-backdrop" onClick={onClose}>
        <div className="image-modal-content" onClick={e => e.stopPropagation()}>
          The beta is currently full. <br/> You'll get access as soon as possible. <br/> <span style={{fontSize:"16px"}}>(PS. Check your gallery! ❤️)</span>
        </div>
      </div>
    );
  };

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setImageUrl("")
  };
  


  const [fullscreenImage, setFullscreenImage] = useState(null);

  const images = [
    { url: '1.png', text: 'Swedish woman, innocent look' },
    { url: '2.png', text: 'Finnish girl, blonde, cute tits' },
    { url: '3.png', text: 'Blonde 24 year old swedish woman, showing butt, posing' },
    { url: '15.png', text: 'Blonde woman, naked pussy' },
    { url: '14.png', text: 'Finnish girl, portrait, face covered in cum' },
    { url: '13.png', text: 'German girl at a techno rave, nice tits' },
    { url: '12.png', text: 'Australian girl, on the beach, on her knees, naked' },
    { url: '11.png', text: 'Black girl, large breasts, posing naked' },
    { url: '10.png', text: 'Swedish girl, pink hair, fit body, large natural breasts, green eyes' }
  ];
  

  const openFullscreen = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };
  
  

  return (
    <div style={{ background:"rgb(10, 0, 23)"}}>
    <div className="App">
      <div className="nav">
        <div className="brand">Lolli<span style={{color:"#F339CA"}}>pop</span></div>
        <div className="links">
        { user ? <div className="link" onClick={openGallery}>My gallery</div> : null}
        
        {user ? <div className="link" onClick={()=>setIsModal3Open(true)}><div>Add credits</div> <div className="creditamount">({credits} left)</div></div> : null}

        {user ? (
          <>
            <div className="link" onClick={signOutUser}>{user.displayName}</div>
          </>
        ) : (
          <div className="link" onClick={signInWithGoogle}>Login</div>
        )}

        <Link to="/" class="btn-101">
          TRY CHAT APP!
          <span style={{fontSize:"10px", fontWeight:"400", marginTop:"-3px"}}>Photos | voice | unfiltered chat</span>
          <svg>
            <defs>
              <filter id="glow">
                <fegaussianblur result="coloredBlur" stddeviation="5"></fegaussianblur>
                <femerge>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="SourceGraphic"></femergenode>
                </femerge>
              </filter>
            </defs>
            <rect />
          </svg>
        </Link>


      </div>
    </div>
    <Link className="trychatmobile" to="/">Chat with Lollipop girls!</Link>
    <div className="rightSide">
    {/*<div className="profile">
      <div style={{fontSize:"24px", fontWeight:"600"}}>Elsa Brigitta, 24</div>
      <div> <img src="https://static-00.iconduck.com/assets.00/location-indicator-red-emoji-1330x2048-pfre7pru.png" width="10px"/> Stockholm, Sweden</div>
      <div style={{fontSize:"14px", marginTop:"10px", textAlign:"left"}}>I like hiking, volleyball, and most of all staying in bed watching movies. First year in adult entertainment.</div>
      <a href="https://www.instagram.com/elsabrigittaswe/" target="_blank" style={{marginTop:"15px", display:"flex", alignItems:"center", gap:"10px", fontSize:"13px", textDecoration:"none", color:"white"}} className="igbutton"><img src="ig.png" width="18px" /> @elsabrigittaswe</a>
    </div>
    */}
      <div className="assist">
      <button className="howto" onClick={()=>setIsModal4Open(true)}>How to prompt?</button>
      <button className="howto" onClick={()=>randomize()}>Need help with imagination</button>
      </div>
      <div className="query">
        <input className="searchbar" type="text" placeholder="Imagine your dream girl..." />
        <button type="button" className="button" disabled={loading} onClick={generateImage}>{loading ? <img src="./loader.svg" width="24px"/> : "Make her"}</button>

      </div>

      </div>
      {isModal2Open && <Modal2 onClose={closeModal2} />}
      {isModal3Open && <Modal3 onClose={closeModal3} />}
      {isModalOpen && <Modal url={imageUrl} onClose={closeModal} />}
      {isGalleryOpen && <GalleryModal email={user.email} onClose={closeGallery} />}
      {isImageModalOpen && <ImageModal onClose={closeImageModal} />}
      {isModal4Open && <Modal4 onClose={closeModal4} />}
      {isUpscaleModalOpen && <UpscaleModal url={upscaledImageUrl} onClose={() => setIsUpscaleModalOpen(false)} />}

    </div>
    {sponsordata.showSponsor ?
    <div className="sponsorWrapper">
      <a href={sponsordata?.link} target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src={sponsordata?.image} height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Sponsor of the day</div>
          <div className="sponsorCardName">{sponsordata?.name}</div>
          <div className="sponsorCardDescription">{sponsordata?.description}</div>
        </div>
      </a>
    </div>
    :
    null
    }
    <div className="promoCards">
    <a className="pHunthref" href="https://www.producthunt.com/posts/lollipop?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-lollipop" target="_blank"><img  className="pHunt" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=434427&theme=light" alt="Lollipop - AI&#0032;girlfriend&#0032;with&#0032;unfiltered&#0032;chatting&#0044;&#0032;voice&#0044;&#0032;and&#0032;photos&#0046; | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
    <a className="pHunthref" href="https://www.reddit.com/r/AIGirlHub/" target="_blank">
      <div className="promoCard">
        <img src="reddit.png" width="32px"/>
        <div className="promoCardText">
          <div className="promoCardSmall">Lollipop's subreddit r/aiGirlHub</div>
          <div className="promoCardTitle">Reddit</div>
        </div>
      </div>
    </a>
    <a className="pHunthref" href="https://www.instagram.com/elsabrigittaswe/" target="_blank">
      <div className="promoCard">
        <img className="promoImage" src="https://countyob.com/wp-content/uploads/2021/08/instagram-logo.png" width="32px"/>
        <div className="promoCardText">
          <div className="promoCardSmall">Our model @elsabrigittaswe</div>
          <div className="promoCardTitle">Instagram</div>
        </div>
      </div>
    </a>
    <a className="pHunthref" href="https://ai-finder.net/ai/lollipopchat" target="_blank">
      <div className="promoCard">
        <img className="promoImage" src="https://avatars.githubusercontent.com/u/127635585?s=200&v=4" width="32px"/>
        <div className="promoCardText">
          <div className="promoCardSmall">AS SEEN ON</div>
          <div className="promoCardTitle">AIFinder</div>
        </div>
      </div>
    </a>
    <a className="pHunthref" href="https://twitter.com/agencylollipop" target="_blank">
      <div className="promoCard">
        <img className="promoImage" src="https://img.freepik.com/free-vector/new-2023-twitter-logo-x-icon-design_1017-45418.jpg?size=338&ext=jpg&ga=GA1.1.632798143.1705323600&semt=ais" width="32px"/>
        <div className="promoCardText">
          <div className="promoCardSmall">Lollipop Agency</div>
          <div className="promoCardTitle">Twitter/X</div>
        </div>
      </div>
    </a>
    </div>
    <div className="about">
      <div className="about-left">
        <h1 className="heading">Generate the most photorealistic AI girls on the internet</h1>
        <div className="mainContent">
          <p>With <span className="highlight">Lollipop</span> you can generate perfect <span className="highlight">AI girls</span>, exactly the way you want them.</p><p>Shape the way she looks, what she wears, how she's posing. Blonde 21 year old swedish girl, completely naked on the couch? Slim Ukrainian woman in sexy red underwear? Any NSFW nudes you can imagine?</p><b>Lollipop can create your perfect AI girlfriend!</b>
        </div>
        <Link to="/" class="btn-101-mobile btn-101" style={{width:"100%", marginTop:"30px"}}>
          TRY CHAT APP!
          <span style={{fontSize:"10px", fontWeight:"400", marginTop:"-3px"}}>Photos | voice | unfiltered chat</span>
          <svg>
            <defs>
              <filter id="glow">
                <fegaussianblur result="coloredBlur" stddeviation="5"></fegaussianblur>
                <femerge>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="coloredBlur"></femergenode>
                  <femergenode in="SourceGraphic"></femergenode>
                </femerge>
              </filter>
            </defs>
            <rect />
          </svg>
        </Link>
      </div>
      <div>
      <div className="about-right"> 
      <center className="yourGirls">Girls like this can all be yours...</center>

      <div className="grid-container">
        {images.map((image, index) => (
          <div className="grid-item" key={index} onClick={() => openFullscreen(image)}>
            <LazyLoadImage className="gridimage" loading="lazy" src={image.url} alt={"AI Girl" + image.text} />
          </div>
        ))}
      </div>
      
      {fullscreenImage && (
        <div className="fullscreen-container" onClick={closeFullscreen}>
          <div className="image-container">
            <LazyLoadImage className="fullimage" loading="lazy" src={fullscreenImage.url} alt={"AI Girl" + fullscreenImage.text} />
            <div className="fullscreen-gradient"></div>
            <div className="fullscreen-text">{fullscreenImage.text}</div>
          </div>
        </div>
      )}

      </div>
      </div>
    </div>

    <div className="cards-container">
      <div className="card">
        <div className="statValue">5,000+</div>
        <div className="statName">Users</div>
      </div>
      <div className="card">
        <div className="statValue">15,830</div>
        <div className="statName">Images generated</div>
      </div>
      <div className="card">
        <div className="statValue">54,561</div>
        <div className="statName">Site visitors</div>
      </div>
      <center style={{opacity:0.4, fontSize:"12px", marginTop:"20px"}}>Stats as of 31 Dec, 2023</center>
    </div>

    <div className="footer">
      <a href="mailto:asynchronousdevelopers@gmail.com" className="footer-link">Contact us</a>
      |
      <Link to="/tos" className="footer-link">Terms of Service</Link>
      |
      <a href="https://twitter.com/AgencyLollipop" target="_blank" className="footer-link">Twitter</a>
      |
      <a href="https://lollipop.chat/feed.html" className="footer-link">News</a>

      </div>

    </div>
  );
}

export default App;
