import React from 'react';
import './AdultLanding.css';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase'; // Adjust path as needed

const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com" //https://lollipopchat-ca692e070ff0.herokuapp.com

const AnimatedStats = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const stats = [
    { number: "50k+", text: "Sexy messages daily" },
    { number: "1M+", text: "Cocks milked dry" },
    { number: "#1", text: "Rated by porn experts" }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
        setIsAnimating(false);
      }, 500); // Half of the animation duration
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats">
      {/* Desktop version */}
      <div className="stats-desktop">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <div className="stat-number">{stat.number}</div>
            <div className="stat-text">{stat.text}</div>
          </div>
        ))}
      </div>

      {/* Mobile version with animation */}
      <div className="stats-mobile">
        <div className="stat">
          <div className={`stat-number ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].number}
          </div>
          <div className={`stat-text ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].text}
          </div>
        </div>
      </div>
    </div>
  );
};

const LandingPage = () => {
    const featuresRef = useRef(null);
    const companionsRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isTosModalOpen, setIsTosModalOpen] = useState(false);
    const [destination, setDestination] = useState('/');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
  
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            setIsScrolled(scrollPosition > 50);
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToSection = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGetStarted = (dest = '/') => {
        setDestination(dest);
        setIsTosModalOpen(true);
    };

    const TosModal = ({ onClose }) => {
        const [emailConsent, setEmailConsent] = useState(false);
        
        const signInWithGoogleAndConsent = async () => {
            try {
                const result = await signInWithPopup(auth, provider);
                
                try {
                    const response = await fetch(`${endpoint}/store-consent`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + await result.user.getIdToken(),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: result.user.email,
                            emailConsent: emailConsent,
                            consentDate: new Date().toISOString()
                        })
                    });

                    if (!response.ok) {
                        console.error('Failed to store consent');
                    }
                } catch (error) {
                    console.error('Error storing consent:', error);
                }
                
                setIsTosModalOpen(false);
                navigate(destination);
                
            } catch (error) {
                console.error('Error signing in with Google:', error);
            }
        };
        
        return (
            <div className="backdrop" onClick={onClose}>
                <div className="modal" onClick={e => e.stopPropagation()}>
                    <div className="tosmodalcontent">
                        <img src="13.png" className="tosmodalimage" />
                        <div className="oneclicksignup">Sign up with one click, get free tokens!</div>
                        <div className="accepttos">
                            I am 18 years of age or older,<br/>
                            and <a href="https://lollipop.chat/tos" target="_blank" style={{textDecoration:"underline", color:"white", fontWeight:"bold"}}>I accept the terms and conditions.</a>
                        </div>
                        
                        <div style={{marginBottom:"20px", textAlign:"center"}}>
                            <label className="flex items-center gap-2 text-sm text-white">
                                <input 
                                    type="checkbox"
                                    className="w-4 h-4"
                                    checked={emailConsent}
                                    onChange={(e) => setEmailConsent(e.target.checked)}
                                />
                                <span className="accepttos">
                                    I agree to receive email updates from Lollipop.chat
                                </span>
                            </label>
                        </div>
            
                        <div className="googleLogin logindesktop" onClick={signInWithGoogleAndConsent}>
                            Sign in with Google
                        </div>
                        <div className="googleLogin loginmobile" onClick={signInWithGoogleAndConsent}>
                            Sign in with Google
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <nav className={`nav ${isScrolled ? 'nav-scrolled' : ''}`}>
                <div className="nav-left">
                    <div className="nav-links">
                        <a href="#features" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(featuresRef);
                        }}>Features</a>
                        <a href="#companions" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(companionsRef);
                        }}>Our girls</a>
                        <a href="mailto:lenny@lollipop.chat">Write us</a>
                    </div>
                </div>
                <button className="start-button" onClick={() => handleGetStarted('/')}>Go to chat!</button>
            </nav>

            <div className="landing-container">
                <div className="hero-section">
                    <div className="background">
                        <img src="adultlanding.png"/>
                        <div className="overlay"></div>
                    </div>
          
                    <div className="main-content">
                        <h1 className="main-heading">Best orgasm of your life</h1>
                        <p className="sub-heading">The feeling can't be described.</p>
                        <div className="button-wrapper">
                            <div class="box" onClick={() => handleGetStarted('/')}>
                                <span>I'm ready to cum!</span>
                                <i></i>
                            </div>
                        </div>
                    </div>
          
                    <AnimatedStats />
                </div>

                <div className="companions-section-adult" ref={companionsRef} id="companions">
                    <h2 className="section-title">Meet Your Perfect Companion</h2>
                    <div className="companions-container">
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=chat')}>
                            <img src="annamariagreeting.png" alt="Anna" className="companion-image" />
                            <div className="picCta">Cum with Anna!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Anna Maria</h3>
                                <p>Finnish, our top girl</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=heidi')}>
                            <img src="heidi1.png" alt="Heidi" className="companion-image" />
                            <div className="picCta">Cum with Heidi!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Heidi</h3>
                                <p>German, all you need to know</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=amelia')}>
                            <img src="amelia1.png" alt="Amelia" className="companion-image" />
                            <div className="picCta">Cum with Amelia!</div>

                            <div className="companion-info">
                                <h3 className="companion-name">Amelia</h3>
                                <p>Crazy readhead, be careful</p>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="benefits-section" ref={featuresRef} id="features">
                    <h2 className="section-title">What makes Lollipop amazing?</h2>
                    
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AnimatedChat />
                        </div>
                        <div className="benefit-content">
                            <h3>Chat with no limits</h3>
                            <p>The girls are smart and understand you. But they also go along with anything you're into. Foot fetish? Let's go. War prisoner roleplay? You got it.</p>
                        </div>
                    </div>
          
                    <div className="benefit-row reverse">
                        <div className="benefit-image">
                            <ImageSlideshow />
                        </div>
                        <div className="benefit-content">
                            <h3>Photorealistic images</h3>
                            <p>It's not just a chat. We pride ourselves in realism. What's a chat without photorealistic tits and pussy? Just ask them.</p>
                        </div>
                    </div>
          
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AudioPlayer />
                        </div>
                        <div className="benefit-content">
                            <h3>Voice messages</h3>
                            <p>Did we mention Lollipop does ultra-realistic voice messages? Just ask, and your companion will send you hot voice messages.</p>
                        </div>
                    </div>
                </div>
          

            </div>
            {isTosModalOpen && <TosModal onClose={() => setIsTosModalOpen(false)} />}
        </>
    );
};

const AnimatedChat = () => {
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    
    const conversationLoop = [
        { id: 1, type: 'ai', text: "Hey sexy, what do you wanna do?", delay: 0 },
        { id: 2, type: 'user', text: "Stressful day. Tell me how you suck me off.", delay: 2000 },
        { id: 3, type: 'ai', text: "Aww. Well, I'll kiss your abs, pull your boxers down with my mouth...", delay: 4000 },
        { id: 4, type: 'user', text: "Keep going.", delay: 7000 },
        { id: 5, type: 'ai', text: "I slide my tongue up your shaft and spit on it. You grab my hair and shove it in my throat.", delay: 10000 },
        { id: 6, type: 'user', text: "Fuck yea, make me cum.", delay: 13000 },
        { id: 7, type: 'ai', text: "I grab your hips and suck it. I get faster and faster as you moan. And I feel you explode in my mouth. I slow down and watch you quiver in pleasure.", delay: 16000 }

    ];
  
    useEffect(() => {
        const addMessage = (message) => {
            setMessages(prev => [...prev, message]);
        };
  
        const startConversation = () => {
            setMessages([]); // Clear messages
            conversationLoop.forEach(message => {
                setTimeout(() => {
                    addMessage(message);
                }, message.delay);
            });
        };
  
        startConversation();
        const interval = setInterval(startConversation, 23000); // Restart after last message
  
        return () => clearInterval(interval);
    }, []);
  
    return (
        <div className="chat-container-adult">
            <div className="chat-header">
                <div className="chat-avatar">
                    <img src="ninagreeting.png" alt="AI Avatar" />
                </div>
                <div className="chat-status">
                    <div className="chat-name">Nina</div>
                    <div className="chat-indicator">Always here for you</div>
                </div>
            </div>
            
            <div className="chat-messages" ref={chatContainerRef}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`chat-message ${message.type === 'ai' ? 'ai' : 'user'}`}
                    >
                        {message.text}
                    </div>
                ))}
            </div>
        </div>
    );
};


// Add this component in the same file, before the export

const ImageSlideshow = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const images = [
        "13.png",
        "14.png",
        "15.png",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slideshow-container-adult">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="slideshow-image"
                    style={{
                        opacity: index === currentImageIndex ? 1 : 0,
                        backgroundImage: `url(${image})`
                    }}
                />
            ))}
        </div>
    );
};

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [duration, setDuration] = useState('0:00');

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const audio = audioRef.current;
        
        const handleTimeUpdate = () => {
            const progress = (audio.currentTime / audio.duration) * 100;
            setProgress(progress);
            setCurrentTime(formatTime(audio.currentTime));
        };

        const handleLoadedMetadata = () => {
            setDuration(formatTime(audio.duration));
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        
        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    const handleProgressChange = (e) => {
        const newProgress = e.target.value;
        const time = (newProgress / 100) * audioRef.current.duration;
        audioRef.current.currentTime = time;
        setProgress(newProgress);
    };

    return (
        <div className="audio-wrap">
            <audio ref={audioRef} src="vanillasound.mp3" />
            
            <div className="audio-content">
            <div className="audio-avatar">
                <img src="ninagreeting.png" alt="Nina" />
                <button onClick={togglePlay} className="play-button">
                    {isPlaying ? '❚❚' : '▶'}
                </button>
            </div>

                <div className="audio-controls">

                    
                    <div className="progress-wrap">
                        <input
                            type="range"
                            value={progress}
                            onChange={handleProgressChange}
                            className="progress-slider"
                        />
                        <div className="time-info">
                            <span>{currentTime}</span>
                            <span>{duration}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default LandingPage;