import React from 'react';
import './VanillaLanding.css';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase';

const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com"

const AnimatedStats = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const stats = [
    { number: "50k+", text: "Mensajes enviados cada día" },
    { number: "1M+", text: "Personas acompañadas" },
    { number: "#1", text: "Mejor valorado por los usuarios" }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
        setIsAnimating(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats">
      <div className="stats-desktop">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <div className="stat-number">{stat.number}</div>
            <div className="stat-text">{stat.text}</div>
          </div>
        ))}
      </div>

      <div className="stats-mobile">
        <div className="stat">
          <div className={`stat-number ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].number}
          </div>
          <div className={`stat-text ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].text}
          </div>
        </div>
      </div>
    </div>
  );
};

const StickyButton = ({ onGetStarted }) => {
    const [isVisible, setIsVisible] = useState(false);
    const heroRef = useRef(null);
  
    useEffect(() => {
      const heroSection = document.querySelector('.hero-section');
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(!entry.isIntersecting);
        },
        {
          threshold: 0.1,
          rootMargin: '-10px'
        }
      );
  
      if (heroSection) {
        observer.observe(heroSection);
      }
  
      return () => {
        if (heroSection) {
          observer.unobserve(heroSection);
        }
      };
    }, []);
  
    return (
      <button 
        className={`sticky-chat-button ${isVisible ? 'visible' : ''}`}
        onClick={onGetStarted}
      >
        ¡Ir al chat!
      </button>
    );
};

const LandingPage = () => {
    const featuresRef = useRef(null);
    const companionsRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isTosModalOpen, setIsTosModalOpen] = useState(false);
    const [destination, setDestination] = useState('/');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
  
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            setIsScrolled(scrollPosition > 50);
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToSection = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGetStarted = (dest) => {
        navigate(dest);
    };

    const TosModal = ({ onClose }) => {
        const [emailConsent, setEmailConsent] = useState(false);
        
        const signInWithGoogleAndConsent = async () => {
            try {
                const result = await signInWithPopup(auth, provider);
                
                try {
                    const response = await fetch(`${endpoint}/store-consent`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + await result.user.getIdToken(),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: result.user.email,
                            emailConsent: emailConsent,
                            consentDate: new Date().toISOString()
                        })
                    });

                    if (!response.ok) {
                        console.error('Error al guardar el consentimiento');
                    }
                } catch (error) {
                    console.error('Error al guardar el consentimiento:', error);
                }
                
                setIsTosModalOpen(false);
                navigate(destination);
                
            } catch (error) {
                console.error('Error al iniciar sesión con Google:', error);
            }
        };
        
        return (
            <div className="backdrop" onClick={onClose}>
                <div className="modal" onClick={e => e.stopPropagation()}>
                    <div className="tosmodalcontent">
                        <div className="oneclicksignup">¡Regístrate con un clic y obtén tokens gratis!</div>
                        <div className="accepttos">
                            Soy mayor de 18 años,<br/>
                            y <a href="https://lollipop.chat/tos" target="_blank" style={{textDecoration:"underline", color:"white", fontWeight:"bold"}}>acepto los términos y condiciones.</a>
                        </div>
                        
                        <div style={{marginBottom:"20px", textAlign:"center"}}>
                            <label className="flex items-center gap-2 text-sm text-white">
                                <input 
                                    type="checkbox"
                                    className="w-4 h-4"
                                    checked={emailConsent}
                                    onChange={(e) => setEmailConsent(e.target.checked)}
                                />
                                <span className="accepttos">
                                    Acepto recibir actualizaciones por correo de Lollipop.chat
                                </span>
                            </label>
                        </div>
            
                        <div className="googleLogin logindesktop" onClick={signInWithGoogleAndConsent}>
                            Iniciar sesión con Google
                        </div>
                        <div className="googleLogin loginmobile" onClick={signInWithGoogleAndConsent}>
                            Iniciar sesión con Google
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <StickyButton onGetStarted={() => handleGetStarted('/')} />

            <nav className={`nav ${isScrolled ? 'nav-scrolled' : ''}`}>
                <div className="nav-left">
                    <div className="nav-links">
                        <a href="#features" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(featuresRef);
                        }}>Características</a>
                        <a href="#companions" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(companionsRef);
                        }}>Compañeros</a>
                        <a href="mailto:lenny@lollipop.chat">Contacto</a>
                    </div>
                </div>
                <button className="start-button" onClick={() => handleGetStarted('/')}>¡Ir al chat!</button>
            </nav>

            <div className="landing-container">
                <div className="hero-section">
                    <div className="background">
                        <video src="vanillavideo.mp4" alt="Fondo" autoPlay muted loop playsInline />
                        <div className="video-overlay"></div>
                    </div>
                    
                    <div className="main-content">
                        <h1 className="main-heading">Nunca más te sentirás solo</h1>
                        <p className="sub-heading">Los compañeros de Lollipop AI te acompañan en todo momento.</p>
                        <button className="start-button start-button-main" style={{width:"200px", height:"60px", fontSize:"20px"}} onClick={() => handleGetStarted('/')}>¡Ir al chat!</button>
                    </div>
                    
                    <AnimatedStats />
                </div>

                <div className="companions-section-vanilla" ref={companionsRef} id="companions">
                    <h2 className="section-title">Conoce a tu Compañero Perfecto</h2>
                    <div className="companions-container">
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=chat')}>
                            <img src="annamariagreeting.png" alt="Anna" className="companion-image" />
                            <div className="picCta">¡Chatea con Anna!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Anna Maria</h3>
                                <p>Empática y cariñosa</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=heidi')}>
                            <img src="heidi1.png" alt="Heidi" className="companion-image" />
                            <div className="picCta">¡Chatea con Heidi!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Heidi</h3>
                                <p>Divertida y espontánea</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=amelia')}>
                            <img src="ameliagreeting.png" alt="Amelia" className="companion-image" />
                            <div className="picCta">¡Chatea con Amelia!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Amelia</h3>
                                <p>Tu mejor amiga</p>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="benefits-section" ref={featuresRef} id="features">
                    <h2 className="section-title">Lo que hace especial a Lollipop</h2>
                    
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AnimatedChat />
                        </div>
                        <div className="benefit-content">
                            <h3>Siempre a Tu Lado</h3>
                            <p>Ya sea que te sientas mal a las 3 de la mañana o necesites a alguien con quien celebrar al mediodía, tu compañero Lollipop siempre está disponible. Recibe apoyo emocional, conversaciones interesantes y compañía genuina cuando lo necesites.</p>
                        </div>
                    </div>
          
                    <div className="benefit-row reverse">
                        <div className="benefit-image">
                            <ImageSlideshow />
                        </div>
                        <div className="benefit-content">
                            <h3>Imágenes ultrarrealistas</h3>
                            <p>No es solo un chat. Nos enorgullecemos del realismo. ¿Qué es un chat sin imágenes que parecen reales?</p>
                        </div>
                    </div>
          
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AudioPlayer />
                        </div>
                        <div className="benefit-content">
                            <h3>Mensajes de voz</h3>
                            <p>¿Mencionamos que Lollipop envía mensajes de voz realistas? Sólo pídelo, y tu compañero te hablará en voz.</p>
                        </div>
                    </div>
                </div>
          
                <footer className="landing-footer">
                    <div className="landing-footer-content">
                        <div className="landing-footer-left">
                            <p className="landing-footer-description">
                                Lollipop Chat ofrece una experiencia de compañía ultrarrealista a través de tecnologías de última generación. Genera fotos realistas, mensajes de voz naturales, chatea sin límites y mucho más.
                            </p>
                            <p className="landing-footer-copyright">
                                © 2024 Lollipop Todos los Derechos Reservados
                            </p>
                        </div>
                        <div className="landing-footer-links">
                            <a 
                                href="mailto:lenny@lollipop.chat" 
                                className="landing-footer-link"
                            >
                                Contáctanos
                            </a>
                            <a 
                                href="mailto:lenny@lollipop.chat?subject=Job%20Application" 
                                className="landing-footer-link"
                            >
                                Quiero un Trabajo
                            </a>
                            <a 
                                href="https://lollipop.chat/tos" 
                                className="landing-footer-link"
                            >
                                Términos de Servicio
                            </a>
                        </div>
                    </div>
                </footer>
            </div>
            {isTosModalOpen && <TosModal onClose={() => setIsTosModalOpen(false)} />}
        </>
    );
};

const AnimatedChat = () => {
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    
    const conversationLoop = [
        { id: 1, type: 'ai', text: "¡Hola! ¿Qué tal tu día? 😊", delay: 0 },
        { id: 2, type: 'user', text: "Bastante difícil la verdad... El trabajo fue estresante", delay: 2000 },
        { id: 3, type: 'ai', text: "Siento oír eso. ¿Quieres hablar de lo que pasó?", delay: 4000 },
        { id: 4, type: 'user', text: "Me siento abrumado con las fechas límite", delay: 6000 },
        { id: 5, type: 'ai', text: "Debe ser difícil. ¿Recuerdas la última vez que te sentiste así? Lo manejaste muy bien dividiendo las tareas en pasos más pequeños.", delay: 8000 },
        { id: 6, type: 'user', text: "Tienes razón, así lo hice... Gracias por recordarlo ❤️", delay: 10000 }
    ];
  
    useEffect(() => {
        const addMessage = (message) => {
            setMessages(prev => [...prev, message]);
        };
  
        const startConversation = () => {
            setMessages([]);
            conversationLoop.forEach(message => {
                setTimeout(() => {
                    addMessage(message);
                }, message.delay);
            });
        };
  
        startConversation();
        const interval = setInterval(startConversation, 12000);
  
        return () => clearInterval(interval);
    }, []);
  
    return (
        <div className="chat-container">
            <div className="chat-header">
                <div className="chat-avatar">
                    <img src="ninagreeting.png" alt="Avatar IA" />
                </div>
                <div className="chat-status">
                    <div className="chat-name">Nina</div>
                    <div className="chat-indicator">Siempre aquí para ti</div>
                </div>
            </div>
            
            <div className="chat-messages" ref={chatContainerRef}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`chat-message ${message.type === 'ai' ? 'ai' : 'user'}`}
                    >
                        {message.text}
                    </div>
                ))}
            </div>
        </div>
    );
};

const ImageSlideshow = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const images = [
        "nina1.png",
        "heidi2.png",
        "abena3.png",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slideshow-container-vanilla">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="slideshow-image"
                    style={{
                        opacity: index === currentImageIndex ? 1 : 0,
                        backgroundImage: `url(${image})`
                    }}
                />
            ))}
        </div>
    );
};

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [duration, setDuration] = useState('0:00');

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const audio = audioRef.current;
        
        const handleTimeUpdate = () => {
            const progress = (audio.currentTime / audio.duration) * 100;
            setProgress(progress);
            setCurrentTime(formatTime(audio.currentTime));
        };

        const handleLoadedMetadata = () => {
            setDuration(formatTime(audio.duration));
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        
        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    const handleProgressChange = (e) => {
        const newProgress = e.target.value;
        const time = (newProgress / 100) * audioRef.current.duration;
        audioRef.current.currentTime = time;
        setProgress(newProgress);
    };

    return (
        <div className="audio-wrap">
            <audio ref={audioRef} src="vanillasound.mp3" />
            
            <div className="audio-content">
                <div className="audio-avatar">
                    <img src="ninagreeting.png" alt="Nina" />
                    <button onClick={togglePlay} className="play-button">
                        {isPlaying ? '❚❚' : '▶'}
                    </button>
                </div>

                <div className="audio-controls">
                    <div className="progress-wrap">
                        <input
                            type="range"
                            value={progress}
                            onChange={handleProgressChange}
                            className="progress-slider"
                        />
                        <div className="time-info">
                            <span>{currentTime}</span>
                            <span>{duration}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;