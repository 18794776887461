/* global gtag stripe */

import React, { createContext, useContext, useState, useEffect, useRef} from 'react';
import './Chat.css';
import { getAuth, signInWithPopup, GoogleAuthProvider  } from "firebase/auth";
import { signOut } from "firebase/auth";
import { set } from 'react-ga';
import Swal from 'sweetalert2'
import Drawer from '@mui/joy/Drawer';
import Box from '@mui/joy/Box';
import {messaging} from './firebase';
import { getToken } from "firebase/messaging";
import { Link } from "react-router-dom";
import sponsordata from './sponsordata.json';
import '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import ImageWithLoader from './ImageWithLoader';
import ReactGA from 'react-ga';
import Calling from './Calling';
import SignupModal from './SignupModal';


function formatMessageTime(timestamp) {
  if (!timestamp) return '';
  
  try {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { 
      hour: 'numeric', 
      minute: '2-digit',
      hour12: true 
    });
  } catch (e) {
    console.error('Error formatting timestamp:', e);
    return '';
  }
}

const EncryptedModal = ({ onClose }) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
        <div style={{
          padding: "40px 20px",
          textAlign: "center",
          color: "white"
        }}>
          <h2 style={{
            fontSize: "24px",
            marginBottom: "20px",
            background: "linear-gradient(to right, #ba00a7, #ba00a7)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent"
          }}>
            Your Privacy Matters
          </h2>

          <p style={{
            fontSize: "16px",
            lineHeight: "1.6",
            maxWidth: "500px",
            margin: "0 auto 30px auto",
            color: "rgba(255,255,255,0.9)"
          }}>
            We take your privacy <b>very seriously</b>. All your data is <b>strongly encrypted</b>. 
            We <b>do not sell or share</b> any of your chat data.
          </p>

          <button 
            onClick={onClose}
            style={{
              background: "linear-gradient(to right, #ba00a7, #ba00a7)",
              color: "white",
              border: "none",
              padding: "12px 32px",
              borderRadius: "999px",
              fontSize: "16px",
              fontWeight: "600",
              cursor: "pointer",
              transition: "all 0.3s ease",
            }}
            onMouseOver={e => e.target.style.opacity = "0.9"}
            onMouseOut={e => e.target.style.opacity = "1"}
          >
            Got it!
          </button>
        </div>
      </div>
    </div>
  );
};

const SideNav = ({ 
  user, 
  credits, 
  openPaymentModal, 
  setIsSignupModalOpen, 
  setIsFeedbackModalOpen,  // Add this to props
  signOutUser 
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <div 
      className="side-nav"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      style={{ width: isExpanded ? '240px' : '72px' }}
    >
      <div className="side-nav-content">
        <div className="side-nav-logo">
          <div className="logo-circle">
            <img src="icon.png" alt="Lollipop" width="32" height="32" />
          </div>
          <span className={`logo-text ${isExpanded ? 'expanded' : ''}`}>
            Lollipop
          </span>
        </div>

        <div className="side-nav-items">
          {!user ? (
            <div 
              className="side-nav-item" 
              onClick={() => setIsSignupModalOpen(true)}
            >
              <div className="side-nav-icon">
                <img src="icons/loginicon.svg" width="24" height="24" alt="Profile" />
              </div>
              <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                <div className="item-title">Log In</div>
                <div className="item-subtitle">Start chatting</div>
              </div>
            </div>
          ) : (
            <>
              <div 
                className="side-nav-item" 
                onClick={openPaymentModal}
              >
                <div className="side-nav-icon">
                  <img src="icons/crediticon.svg" width="24" height="24" alt="Credits" />
                </div>
                <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                  <div className="item-title">Get credits</div>
                  <div className="item-subtitle">
                    {credits > 0 ? `${credits} available` : 'Buy credits'}
                  </div>
                </div>
              </div>

              <a 
                href="https://billing.stripe.com/p/login/6oE7sC4UZ6DLcUg5kk"
                target="_blank"
                rel="noopener noreferrer" 
                className="side-nav-item"
              >
                <div className="side-nav-icon">
                  <img src="icons/stripeicon.svg" width="24" height="24" alt="Manage" />
                </div>
                <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                  <div className="item-title">Manage Plan</div>
                  <div className="item-subtitle">Subscription settings</div>
                </div>
              </a>

              <div className="side-nav-divider" />


              <div 
                className="side-nav-item" 
                onClick={() => setIsFeedbackModalOpen(true)}
              >
                <div className="side-nav-icon">
                  <img src="icons/feedbackicon.svg" width="24" height="24" alt="Feedback" />
                </div>
                <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                  <div className="item-title">Feedback</div>
                  <div className="item-subtitle">Get free tokens</div>
                </div>
              </div>
              
            </>
          )}

          {/* Common items for both logged in and logged out users */}

          <a href="/generate" className="side-nav-item">
                <div className="side-nav-icon">
                  <img src="icons/hearticon.svg" width="24" height="24" alt="Generate" />
                </div>
                <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                  <div className="item-title">Generate</div>
                  <div className="item-subtitle">Create photos</div>
                </div>
              </a>
          <a 
            href="https://lollipop.chat/affiliate"
            target="_blank"
            rel="noopener noreferrer"
            className="side-nav-item"
          >
            <div className="side-nav-icon">
              <img src="icons/affiliateicon.svg" width="24" height="24" alt="Affiliate" />
            </div>
            <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
              <div className="item-title">Affiliate</div>
              <div className="item-subtitle">Earn with us</div>
            </div>
          </a>

          <a
            href="https://lollipop.chat/partners"
            target="_blank"
            rel="noopener noreferrer"
            className="side-nav-item"
          >
            <div className="side-nav-icon">
              <img
                src="icons/partnersicon.svg"
                width="24"
                height="24"
                alt="Partners"
              />
            </div>
            <div
              className={`side-nav-item-content ${
                isExpanded ? "expanded" : ""
              }`}
            >
              <div className="item-title">Meet</div>
              <div className="item-subtitle">Our partners</div>
            </div>
          </a>

          <a 
            href="mailto:lenny@lollipop.chat"
            className="side-nav-item"
          >
            <div className="side-nav-icon">
              <img src="icons/contacticon.svg" width="24" height="24" alt="Contact" />
            </div>
            <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
              <div className="item-title">Contact</div>
              <div className="item-subtitle">Get in touch</div>
            </div>
          </a>

          {user && (
            <>
                      <div className="side-nav-divider" />

            
            <div 
              className="side-nav-item logout-item" 
              onClick={signOutUser}
            >
              <div className="side-nav-icon">
                <img src="icons/logouticon.svg" width="24" height="24" alt="Logout" />
              </div>
              <div className={`side-nav-item-content ${isExpanded ? 'expanded' : ''}`}>
                <div className="item-title">Log Out</div>
                <div className="item-subtitle">Sign out</div>
              </div>
            </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
function Chat() {
  const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com" //https://lollipopchat-ca692e070ff0.herokuapp.com
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [user, setUser] = useState(null);
  const [paid, setPaid] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEncryptedModalOpen, setEncryptedModalOpen] = useState(false);

// In your component:
const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

useEffect(() => {
  if (isSignupModalOpen) {
    //close the mobile menu
    setOpen(false);
  }
}, [isSignupModalOpen]);



const handleSignupSubmit = (credentials) => {
  // Handle the signup logic
  console.log(credentials);
};
useEffect(() => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const priceId = urlParams.get('priceid');
    const subscriptionId = urlParams.get('subscription');

    // Handle one-time purchase
    if (priceId && window.gtag) {
      const trxid = (Math.random().toString(36) + '0000000000').substring(2, 12);
      let value;

      // One-time purchase IDs
      if(priceId == "price_1OUnhhEsKxxKHfJy2BGMvbsK") value = 2.99;
      if(priceId == "price_1OUnjlEsKxxKHfJy8urThzlC") value = 9.99;
      if(priceId == "price_1OUnkXEsKxxKHfJysI3RH4pl") value = 49.99;

      window.gtag('event', 'conversion_event_purchase', {
        event_category: 'E-commerce',
        event_label: 'Subscription Started',
        transaction_id: trxid,
        value: value,
        currency: 'EUR',
        items: [{
          item_id: subscriptionId,
          item_name: "Monthly Subscription",
          price: value,
          subscription: true
        }]
      });

      window.gtag('event', 'purchase', {
        event_category: 'E-commerce',
        event_label: 'One-Time Purchase',
        transaction_id: trxid,
        value: value,
        currency: 'EUR',
        items: [{
          item_id: priceId,
          item_name: "Tokens",
          price: value,
          quantity: 1
        }]
      });
    }

    // Handle subscription
    if (subscriptionId && window.gtag) {
      const trxid = (Math.random().toString(36) + '0000000000').substring(2, 12);
      let value;

      // Subscription IDs
      if(subscriptionId == "price_1QGHBPEsKxxKHfJyyPE9PujY") value = 2.99;
      if(subscriptionId == "price_1QGfm8EsKxxKHfJy9FrKBIDs") value = 9.99;
      if(subscriptionId == "price_1QGfn9EsKxxKHfJyjfN7ms1R") value = 49.99;

      window.gtag('event', 'conversion_event_subscribe_paid', {
        event_category: 'E-commerce',
        event_label: 'Subscription Started',
        transaction_id: trxid,
        value: value,
        currency: 'EUR',
        items: [{
          item_id: subscriptionId,
          item_name: "Monthly Subscription",
          price: value,
          subscription: true
        }]
      });

      window.gtag('event', 'subscription', {
        event_category: 'E-commerce',
        event_label: 'Subscription Started',
        transaction_id: trxid,
        value: value,
        currency: 'EUR',
        items: [{
          item_id: subscriptionId,
          item_name: "Monthly Subscription",
          price: value,
          subscription: true
        }]
      });
    }

    // Clean up URL - only include ? if we have parameters
    if (priceId) urlParams.delete('priceid');
    if (subscriptionId) urlParams.delete('subscription');
    
    const queryString = urlParams.toString();
    const newUrl = queryString 
      ? `${window.location.pathname}?${queryString}`
      : window.location.pathname;
    window.history.replaceState({}, '', newUrl);

  } catch(e) {
    console.log(e)
  }
}, []);

  const sweety = (message) => {
      Swal.fire({
        text: message,
        background: "rgba(255, 0, 189, 0.9)",
        position: 'top',
        showConfirmButton: false,
        color:"white",
        backdrop:false,
        timer: 5000,
        timerProgressBar: true,
        fontSize:"12px",
        width:"100vw"
      })
    }

  useEffect(() => {

      auth.onAuthStateChanged(user => {
        setUser(user);
      });
    }, [auth]);

    const [models, setModels] = useState([]);

  useEffect(() => {
    fetch(`${endpoint}/models`, {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'max-age=0'

      },
    })
    .then(response => response.json())
    .then(data => {
      setModels(data)
    })
  }, [])


  const [model, setModel] = useState();

  useEffect(() => {
    let girl = new URLSearchParams(window.location.search).get('lollipopgirl') || "chat"
    setModel(girl)
    selectModel(girl)
  }, [models])

  const [modelData, setModelData] = useState({});

  const selectModel = (model_key) => {
    /**
    if(messageLoading){
      sweety("Wait for the reply first!")
      return
    }
    */
    setModel(model_key)
    const modelObject = models?.find(model => model.key == model_key)
    setModelData(modelObject)
    setOpen(false)
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  }

  const getModel = async () => {
    models?.find(model => model.key === model)
  }

    
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
      setIsTosModalOpen(false)
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const signInWithGoogleMobile = async () => {
    try {
      await signInWithPopup(auth, provider);
      setIsTosModalOpen(false)
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };


  const [subscribed, setSubscribed] = useState(false);



  const getNotified = () => {
    // Helper function to handle token retrieval and API call
    const getTokenAndCallAPI = () => {
      getToken(messaging, { "vapidKey": "BGHbf8v41DDLpzDY16h3tP2KfAt4rkZbmk832ZPABVuryulJqtzNFQyNegMMFjBA7hurNmfOVuELNo3oCiLfiZw" }).then((currentToken) => {
        if (currentToken) {
  
          fetch(`${endpoint}/notificationsignup`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user?.email, notification: currentToken })
          })
          .then(response => {
            if (response.ok) {
              // Set flag in local storage
              localStorage.setItem('registeredForNotifications', 'true');
              setSubscribed(true);
            } else {
              // Handle non-OK responses
              console.log("Error registering notification with the server.");
            }
            return response.json();
          })
          .then(res => {
            console.log(res);
          });
        } else {
          console.log('No registration token available.');
        }
      }).catch((err) => {
        sweety("Are your push notifications enabled? Look for bell icon above.")
        console.log('An error occurred while retrieving token. ', err);
      });
    };
  
    // Check if the user is already registered for notifications
    const isRegisteredForNotifications = localStorage.getItem('registeredForNotifications') === 'true';
  
    if (!isRegisteredForNotifications) {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          console.log('Requesting permission...');
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              console.log('Notification permission granted.');
              getTokenAndCallAPI();
            } else {
              sweety("Notifications permission denied.");
              console.log('Permission denied by the user.');
            }
          }).catch((err) => {
            sweety("Error requesting notifications permission.");
            console.log('Permission request error: ', err);
          });
        }).catch((err) => {
          console.error('Service Worker not ready:', err);
        });
      } else {
        console.log("Service Worker is not supported by this browser.");
      }
    } else {
      console.log("User is already registered for notifications.");
    }
    
  };

  useEffect(() => {
    if(user){
      const getQueryParam = (name) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(name);
      };
      const referrer = getQueryParam('referrer');

      if(referrer){
        console.log("URL has referrer.")
      }
      else{
        console.log("No referrer in query parameters.")
        return
      }
      if(!localStorage.getItem('referrer')){
        console.log("No referrer in localstorage. Going to set one.")
      }
      else{
        console.log("Already set referrer.")
        return
      }
      const authToken = auth.currentUser.getIdToken();
      if (referrer) {
        fetch(`${endpoint}/referral`, {
          method: 'POST',
          headers: {
              'Authorization': 'Bearer ' + authToken,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ referrer: referrer })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Assuming the server responds with JSON
        })
        .then(res => {
          localStorage.setItem('referrer', getQueryParam('referrer'));

        })
        .catch(error => console.error('Error setting referrer:', error));
      }
    }
  }, [user])

  const signOutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setMessages({});
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


    const bottomRef = useRef(null);

    const [theme, setTheme] = useState('light'); // Default theme is light
    const [messageLoading, setMessageLoading] = useState(false);
    const [messageLoadingBubbles, setMessageLoadingBubbles] = useState({});


    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    const logmein = () =>{
      setIsTosModalOpen(true)
      setOpen(false)
    }


    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    const scrollToBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const [isNearBottom, setIsNearBottom] = useState(false);

    // Function to check if the bottomRef is in view or within 300px
    const checkBottomInView = () => {
      if (bottomRef.current) {
        const rect = bottomRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        const isNear = rect.top < window.innerHeight + 300;
        setIsNearBottom(isVisible || isNear);
      }
    };


  
    useEffect(() => {
      // Check on initial mount
      checkBottomInView();
  
      // Add scroll event listener
      window.addEventListener('scroll', checkBottomInView);
  
      // Cleanup on unmount
      return () => {
        window.removeEventListener('scroll', checkBottomInView);
      };
    }, []);

    // Fetch messages when the component mounts
    useEffect(() => {

      async function fetchMessages() {
      
      const authToken = await auth?.currentUser?.getIdToken();

      if (authToken) {
              fetch(`${endpoint}/get-messages`, {
                  method: 'POST',
                  headers: {
                      'Authorization': 'Bearer ' + authToken,
                      'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({ model_key: model })
              })
                  .then(response => response.json())
                  .then(data => {
  
                      const newMessages = [
                          { role: 'system', content: modelData?.greeting_photo },
                          { role: 'ai', content: modelData?.greeting ? modelData?.greeting : `Hey! I'm ${modelData?.name}. Apparently I'm your girlfriend now. Kinda excited! 🥰`, timestamp: "Just now"},
                          { role: 'ai', content: "By the way... I can chat about ANYTHING. I can send you **voice messages**. Aaand... I can **take photos**. Just ask.", timestamp: "Just now"}
                      ];
  
                      if (data?.length === 0 || data?.error) {
                          setMessages(prevMessages => ({
                              ...prevMessages,
                              [modelData?.name]: newMessages
                          }));
                      } else {
                          setMessages(prevMessages => ({
                              ...prevMessages,
                              [modelData?.name]: newMessages.concat(data)
                          }));
                      }
                  })
                  .catch(error => {
                      console.error('Error fetching messages:', error);
                  });

      } else {
          setMessages(prevMessages => ({
              ...prevMessages,
              [modelData?.name]: [
                  { role: 'system', content: modelData?.greeting_photo },
                  { role: 'ai', content: `Hey! I'm ${modelData?.name}. Apparently I'm your girlfriend now. Kinda excited! 🥰` },
                  { role: 'ai', content: "By the way... I can chat about ANYTHING. I can send you **voice messages**. Aaand... I can **take photos**. Just ask." }
              ]
          }));
      }
    }
    fetchMessages();
  }, [user, modelData?.name]);
  

    
    const generateImage = async () => {
        const authToken = await auth.currentUser.getIdToken();
        let messagetosend = newMessage;
        setNewMessage('');
        setMessageLoading(true)
        fetch(`${endpoint}/generate-image-chat?prompt=${encodeURIComponent(messagetosend)}`,{
            headers: {
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                // Assuming the response contains the image URL
                const imageUrl = data.image_url;
                
                // Update chat messages with the new image URL
                const newImageMessage = { role: 'system', content: imageUrl };
                setMessages([...messages, newImageMessage]);

                // Clear the input field
                setNewMessage('');
                setMessageLoading(false)
            })
            .catch(error => console.error('Error generating image:', error));
    };

    const improveImage = async (url) => {
      if(credits < 2){
        sweety("You don't have enough tokens! Grab some more.")
        openPaymentModal()
        return
      }
      sweety("Upscaling the image. This may take up to a minute.")
      setMessageLoading(true)
      setMessages(prevMessages => ({
        ...prevMessages,
        [modelData?.name]: [
            ...(prevMessages[modelData?.name] || []),
            { role: 'ai', content: "I'll make the image better and send it to you in a minute or so." }
        ]
        }));
          setTimeout(() => {
        scrollToBottom()
      }, 500);

      const authToken = await auth.currentUser.getIdToken();

      fetch('https://us-central1-chaind.cloudfunctions.net/api/upscale-image', {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ imageUrl: url })
    })
    .then(response => {
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json(); // Assuming the server responds with JSON
    })
    .then(res => {
        setMessages(prevMessages => ({
          ...prevMessages,
          [modelData?.name]: [
              ...(prevMessages[modelData?.name] || []),
              res
          ]
        }));
        setMessageLoading(false);
        sweety("Image upscaled. Enjoy!");
        setCredits(credits-2);
        setTimeout(() => {
            scrollToBottom();
        }, 500);
    })
    .catch(error => {
        console.error('Error generating image:', error);
        sweety("Something went wrong. Please try again.");
        setMessageLoading(false);
    });
    
    }

    const redirectToCustomerPortal = async () => {
      try {
        const authToken = await auth.currentUser.getIdToken();
        const response = await fetch(`${endpoint}/create-portal-session`, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + authToken,
            'Content-Type': 'application/json',
          }
        });
    
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
    
        const { url } = await response.json();
        window.location.href = url;
      } catch (error) {
        console.error('Error:', error);
        sweety("Something went wrong. Please try again.");
      }
    };

    
    const generateVoice = async () => {
        const authToken = await auth.currentUser.getIdToken();
        let messagetosend = newMessage;
        setNewMessage('');
        setMessageLoading(true)
        fetch(`${endpoint}/generate-voice`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + authToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ message: messagetosend })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json(); // Assuming the server responds with JSON
        })
        .then(res => {
            const audioUrl = res.audio_url;
            const newVoiceMessage = { role: 'audio', content: audioUrl, type: 'audio' };
            setMessages(prevMessages => ({
              ...prevMessages,
              [modelData?.name]: [
                  ...(prevMessages[modelData?.name] || []),
                  newVoiceMessage
              ]
          }));
            setNewMessage('');
            setMessageLoading(false)
        })
        .catch(error => console.error('Error generating voice:', error));
    };    
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };
    


    const sendMessage = async () => {
      if(!user){
          setIsSignupModalOpen(true)
          return
      }
      if(credits <= 0){
          sweety("You don't have enough tokens! Grab some more.")
          openPaymentModal()
          return
      }
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
  
      if(messageLoading){
          sweety("You have an ongoing conversation already, wait for the messages.")
          return
      }
      const authToken = await auth.currentUser.getIdToken();
  
      setMessageLoading(true)
      setTimeout(() => {
          setMessageLoadingBubbles(prevState => ({
              ...prevState,
              [modelData?.name]: true
          }));
          setTimeout(() => {
              bottomRef.current.scrollIntoView({ behavior: "smooth" });
          }, 100);
      }, 1000);
  
      const userMessage = { 
          role: 'user', 
          content: newMessage,
          timestamp: new Date().toISOString()
      };
  
      setMessages(prevMessages => ({
          ...prevMessages,
          [modelData?.name]: [
              ...(prevMessages[modelData?.name] || []),
              userMessage
          ]
      }));
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
  
      let messagetosend = newMessage;
      setNewMessage('');
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
  
      try {
          const response = await fetch(`${endpoint}/chatapp`, {
              method: 'POST',
              headers: {
                  'Authorization': 'Bearer ' + authToken,
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify({ message: messagetosend, model: model })
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const responseText = await response.text();
  
          try {
              const responseData = JSON.parse(responseText);
              const messageWithTimestamp = {
                  ...responseData,
                  timestamp: responseData.timestamp || new Date().toISOString()
              };
              
              setMessages(prevMessages => ({
                  ...prevMessages,
                  [modelData?.name]: [
                      ...(prevMessages[modelData?.name] || []),
                      messageWithTimestamp
                  ]
              }));
          } catch (e) {
              throw new Error("Received non-JSON response from server.");
          }
  
          setMessageLoading(false);
          setMessageLoadingBubbles(prevState => ({
              ...prevState,
              [modelData?.name]: false
          }));
          setCredits(credits - 1);
  
      } catch (error) {
          console.error('Error sending message:', error);
          sweety("Something went wrong, please try again.");
          setMessageLoading(false);
          setMessageLoadingBubbles(prevState => ({
              ...prevState,
              [modelData?.name]: false
          }));
      }
  };

    const [credits, setCredits] = useState(0);

    useEffect(() => {
        if(user){
            fetchCredits(user?.email); 
        }
    }, [user]);

    const [isSubscribed, setIsSubscribed] = useState(false);


    const fetchCredits = async (userEmail) => {
      try {
          const response = await fetch(`${endpoint}/read-credits`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: userEmail }),
          });
      
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
      
          const data = await response.json();
      
          if (data.status === 200 && data.error_code === 0) {
              setPaid(data?.paid);
              setIsSubscribed(data?.is_subscribed);
              setCredits(data.token_balance);
          } else {
              console.error('Error fetching credits:', data.message);
              return null;
          }
      } catch (error) {
          console.error('There was a problem fetching user credits:', error);
      }
  };

      const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
      
      const openPaymentModal = () => {
        setOpen(false);
        setIsPaymentModalOpen(true);
      }


      const FeedbackModal = ({onClose}) => {
        const [feedBack, setFeedBack] = useState("");
  
        const sendFeedback = async () => {
          if(feedBack.length < 30){
            sweety("Please think it through and write proper feedback.")
            return
          }
          setIsFeedbackModalOpen(false)


          const authToken = await auth.currentUser.getIdToken();

          fetch(`${endpoint}/feedback`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + authToken,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user?.email, message: feedBack })
          })
          .then(response => {
            if(response.ok){
              sweety("Thanks for the feedback! You got 5 free tokens.")
              fetchCredits(user?.email)
            }
            else{
              sweety("Feedback submitted. No free tokens though, as you already gave feedback.")
            }
            return response.json();
          })
          .then(res => {
            console.log(res);
          });
        
      }
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal modal3" onClick={e => e.stopPropagation()}>
              <div className="feedbackTitle">Submit feedback, get 5 free tokens!</div>
              <textarea rows={10} className="feedbackInput" value={feedBack} placeholder="Write your detailed feedback here. Any problems you had, your wishlist for features you'd like to use. Anything is welcome!" onChange={(e)=>setFeedBack(e.target.value)}></textarea>
              <button className="feedbackButton" onClick={()=>sendFeedback()}>Submit feedback</button>
            </div>
          </div>
        );
      }

      const [isSponsorModalOpen, setIsSponsorModalOpen] = useState(false);


const SponsorModal = ({ url, onClose }) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
      <div className="sponsorWrapper2">
      <a href="https://www.aipornmakers.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="aipornmakers.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">The only list you'll ever need.</div>
          <div className="sponsorCardName">AIPornMakers.com</div>
          <div className="sponsorCardDescription">The ultimate AI Porn maker list.</div>
        </div>
      </a>
      <a href="https://www.theporngoat.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porngoat.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Explore a world of unlimited pleasure</div>
          <div className="sponsorCardName">ThePornGoat.com</div>
          <div className="sponsorCardDescription">Safest porn sites of all time.</div>
        </div>
      </a>
      <a href="https://bestpremiumpornsite.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porncouch.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Best Premium Porn Site</div>
          <div className="sponsorCardName">BestPremiumPornSite.com</div>
          <div className="sponsorCardDescription">Best adult world directory.</div>
        </div>
      </a>
      <a href="https://www.theporncouch.com" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="porncouch.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Your sofa is waiting.</div>
          <div className="sponsorCardName">ThePornCouch.com</div>
          <div className="sponsorCardDescription">The ultimate safe porn list.</div>
        </div>
      </a>
      <a href="https://www.aipornhub.net/" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="https://www.aipornhub.net/images/dark-logo.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Detailed generation with full control.</div>
          <div className="sponsorCardName">AIPornhub.net</div>
          <div className="sponsorCardDescription">Advanced NSFW art generation.</div>
        </div>
      </a>
      <a href="https://reddit.com/r/aipornhub" target="_blank" className="sponsorCard">
        <img style={{borderRadius:"5px"}} src="reddit.png" height="80px"></img>
        <div className="sponsorCardTextWrapper">
          <div className="sponsorCardTitle">Reddit's best AI Porn</div>
          <div className="sponsorCardName">r/AIPornHub</div>
          <div className="sponsorCardDescription">Biggest NSFW art subreddit.</div>
        </div>
      </a>
    </div>
      </div>
    </div>
  );
};   

// Add this helper function at the top level
const subscriptionWithStripe = async (price_id, user, endpoint, sweety) => {
  const stripe = await loadStripe('pk_live_51OMTabEsKxxKHfJyzJZA51h82xgLRz3YxS1B8CWWRaMPDl9ta5x8PRQNCAebuSouKr1bYOKEU2LgE9t4nc1R49mY00qqxFba3v');

  fetch(`${endpoint}/create-subscription-checkout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      tolt_referral: window.tolt_referral,
      email: user?.email, 
      price_id: price_id
    }),
  })
  .then(response => {
    if (response.ok) return response.json();
    throw new Error('Network response was not ok.');
  })
  .then(session => {
    if(session.id) {
      stripe.redirectToCheckout({sessionId: session.id});
    } else {
      throw new Error('Session ID is undefined.');
    }
  })
  .catch(error => {
    sweety("There was an error processing your request. Please try again.");
  });
};

const paymentWithStripe = async (price_id) => {
  const stripe = await loadStripe('pk_live_51OMTabEsKxxKHfJyzJZA51h82xgLRz3YxS1B8CWWRaMPDl9ta5x8PRQNCAebuSouKr1bYOKEU2LgE9t4nc1R49mY00qqxFba3v');

  fetch(`${endpoint}/create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({tolt_referral: window.tolt_referral, email:user?.email, price_id: price_id}), // Make sure this matches your server-side logic
  })
  .then(response => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Network response was not ok.');
    }
  })
  .then(session => {
    // Debugging: Log the session ID to make sure it's received correctly
    console.log("Session ID:", session.id);
    
    if(session.id) {
      stripe.redirectToCheckout({sessionId: session.id});
    } else {
      throw new Error('Session ID is undefined.');
    }
  })
  .catch(error => console.error('Error:', error));
};

const [canCall, setCanCall] = useState(false);

const signUserForCalls = async () => {
  if(localStorage.getItem('callsignup')){
    sweety("You're already signed up for the beta.")
    return
  }
  fetch(`${endpoint}/callsignup`, {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: user?.email })
  })
  .then(response => response.json())
  .then(data => {
    sweety("You have been signed up for the beta. We'll notify you when you have access.")
    localStorage.setItem('callsignup', 'true');

  })
}

const CallSignUp = () => {
  return(
    <div>
      <div className="oneclicksignup">Live calls are here. </div>
      <div className="oneclicksignup" style={{fontSize:"24px", marginBottom:"20px"}}>Check out the 1-minute demo!</div>
<iframe id="ytplayer" type="text/html" width="640" height="390" style={{borderRadius:"10px"}}
src="http://www.youtube.com/embed/M7lc1UVf-VE?autoplay=1&origin=http://example.com"
frameborder="0"/>
<div className="signUpForCallsButton" onClick={() => paid ? signUserForCalls() : sweety("The beta is for any premium plan members.")}>Click here to apply for the beta!</div>

    </div>
  )
}

const CallingModal = ({ onClose }) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
        {canCall ? <Calling /> : 
        <CallSignUp />
        }
      </div>
    </div>
  );
};

const [isCallingModalOpen, setIsCallingModalOpen] = useState(false);


// Updated PaymentModal keeping your exact same design
const PaymentModal = ({ 
  onClose, 
  user, 
  endpoint, 
  sweety, 
  isSubscribed,
  subscriptionWithStripe,
  paymentWithStripe
}) => {
  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
        <div className="stripePayments">
          <img src="stripe.png" width="32px" alt="Stripe"/> 
          Secure payments with Stripe
        </div>
        <div style={{position:"absolute", top:"5px", right:"5px", cursor:"pointer"}} onClick={onClose}><img src="closeicon.png" width="24px" height="24px"/></div>

        <div className="payment-options">
          {!isSubscribed ? (
            // Show subscription options for non-subscribed users
            <>
              <div className="payment-card">
                <h3>Starter Fantasy</h3>
                <p>Love pussy, but want a sweet deal? <br/>This is for you!</p>
                <div className="payButtonWrapper">
                  <a 
                    className="payButton"
                    onClick={() => subscriptionWithStripe("price_1QGHBPEsKxxKHfJyyPE9PujY")}
                  >
                    <span className="strike">€7.99</span> €2.99/month
                  </a>
                </div>
                <div className="tokenAmount">25 Monthly Tokens</div>
              </div>

              <div className="payment-card middle">
                <h3>Enhanced Ecstasy</h3>
                <p>You're an absolute legend, who needs more than the average guy.</p>
                <div className="payButtonWrapper">
                  <a 
                    className="payButton"
                    onClick={() => subscriptionWithStripe("price_1QGfm8EsKxxKHfJy9FrKBIDs")}
                  >
                    <span className="strike">€19.99</span> €9.99/month
                  </a>
                </div>
                <div className="tokenAmount">100 Monthly Tokens</div>
              </div>

              <div className="payment-card">
                <h3>Limitless Lust</h3>
                <p>Johnny Sins himself is afraid of you. <br/> We are too. <b>You're a king.</b></p>
                <div className="payButtonWrapper">
                  <a 
                    className="payButton"
                    onClick={() => subscriptionWithStripe("price_1QGfn9EsKxxKHfJyjfN7ms1R")}
                  >
                    €49.99/month
                  </a>
                </div>
                <div className="tokenAmount">1000 Monthly Tokens</div>
              </div>
            </>
          ) : (
            // Show one-time purchase options for subscribed users
<>
  <div className="payment-card">
    <h3>Starter Fantasy</h3>
    <p>Love pussy, but want a sweet deal? <br/>This is for you!</p>
    <div className="payButtonWrapper">
      <a 
        className="payButton"
        onClick={() => paymentWithStripe("price_1OUnhhEsKxxKHfJy2BGMvbsK")}
      >
        <span className="strike">€7.99</span> €2.99
      </a>
    </div>
    <div className="tokenAmount">25 Tokens</div>
  </div>

  <div className="payment-card middle">
    <h3>Enhanced Ecstasy</h3>
    <p>You're an absolute legend, who needs more than the average guy.</p>
    <div className="payButtonWrapper">
      <a 
        className="payButton"
        onClick={() => paymentWithStripe("price_1OUnjlEsKxxKHfJy8urThzlC")}
      >
        <span className="strike">€19.99</span> €9.99
      </a>
    </div>
    <div className="tokenAmount">100 Tokens</div>
  </div>

  <div className="payment-card">
    <h3>Limitless Lust</h3>
    <p>Johnny Sins himself is afraid of you. <br/> We are too. <b>You're a king.</b></p>
    <div className="payButtonWrapper">
      <a 
        className="payButton"
        onClick={() => paymentWithStripe("price_1OUnkXEsKxxKHfJysI3RH4pl")}
      >
        €49.99
      </a>
    </div>
    <div className="tokenAmount">1000 Tokens</div>
  </div>
</>
          )}
        </div>

        <div className="disclaimer">
          {!isSubscribed 
            ? "No hidden fees. Cancel at any time." 
            : "One-time purchase. Tokens are added instantly to your account."}
        </div>
      </div>
    </div>
  );
};


      const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);

useEffect(() => {
  if (window.location.pathname === '/unsubscribe-emails') {
    setIsUnsubscribeModalOpen(true);
  }
}, [user]);

const UnsubscribeModal = ({ onClose }) => {
  const handleUnsubscribe = async () => {
    try {
      const authToken = await auth.currentUser.getIdToken();
      const response = await fetch(`${endpoint}/update-email-consent`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + authToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          emailConsent: false,
          unsubscribeDate: new Date().toISOString()
        })
      });

      if (response.ok) {
        sweety("You've been successfully unsubscribed from our emails.");
        onClose();
        // Clear the URL to base path without refresh
        window.history.pushState({}, '', '/');
      } else {
        throw new Error('Failed to unsubscribe');
      }
    } catch (error) {
      console.error('Error unsubscribing:', error);
      sweety("Something went wrong. Please try again.");
    }
  };

  return (
    <div className="backdrop" onClick={onClose}>
      <div className="modal modal3" onClick={e => e.stopPropagation()}>
        <div style={{ textAlign: 'center', padding: '20px' }}>
        {user ?
          <button
            className="payButton"
            onClick={handleUnsubscribe}
          >
            Unsubscribe from all emails
          </button>
          :
          <div style={{ textAlign: 'center', padding: '20px' }}>
          Please log in to unsubscribe from emails.<br/>
      </div>
        }
        </div>
      </div>
      
    </div>
  );
};

      const [isTosModalOpen, setIsTosModalOpen] = useState(false);

      const TosModal = ({ onClose }) => {
        const [emailConsent, setEmailConsent] = useState(false);
        
        const signInWithGoogleAndStoreConsent = async () => {
          try {
            // Original signin
            const result = await signInWithPopup(auth, provider);
        
            // Track login event if not already tracked this session
            if (!localStorage.getItem('hasTrackedLogin') && window.gtag) {
              window.gtag('event', 'login', {
                event_category: 'User',
                event_label: 'Google Login',
                method: 'Google'
              });
              localStorage.setItem('hasTrackedLogin', 'true');
            }
            
            console.log("Storing consent for:", result.user.email);
            
            // Store the consent after successful sign in
            try {
              const response = await fetch(`${endpoint}/store-consent`, {
                method: 'POST',
                headers: {
                  'Authorization': 'Bearer ' + await result.user.getIdToken(),
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  email: result.user.email,
                  emailConsent: emailConsent,
                  consentDate: new Date().toISOString()
                })
              });
        
              if (!response.ok) {
                console.error('Failed to store consent');
              }
            } catch (error) {
              console.error('Error storing consent:', error);
              // We don't throw here because we want the sign in to complete even if consent storage fails
            }
            
            // Original post-signin behavior
            setIsTosModalOpen(false);
            
          } catch (error) {
            console.error('Error signing in with Google:', error);
          }
        };
        
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal" onClick={e => e.stopPropagation()}>
              <div className="tosmodalcontent">
                <div className="oneclicksignup">Sign up with one click, get free tokens!</div>
                <div className="accepttos">
                  I am 18 years of age or older,<br/>
                  and <a href="https://lollipop.chat/tos" target="_blank" style={{textDecoration:"underline", color:"white", fontWeight:"bold"}}>I accept the terms and conditions.</a>
                </div>
                
                <div style={{marginBottom:"20px", textAlign:"center"}}>
                  <label className="flex items-center gap-2 text-sm text-white">
                    <input 
                      type="checkbox"
                      className="w-4 h-4"
                      checked={emailConsent}
                      onChange={(e) => setEmailConsent(e.target.checked)}
                    />
                    <span className="accepttos">
                      I agree to receive email updates from Lollipop.chat
                    </span>
                  </label>
                </div>
      
                <div className="googleLogin logindesktop" onClick={signInWithGoogleAndStoreConsent}>
                  Sign in with Google
                </div>
                <div className="googleLogin loginmobile" onClick={signInWithGoogleAndStoreConsent}>
                  Sign in with Google
                </div>
              </div>
            </div>
          </div>
        );
      }; 
    

    const isImageUrl = (url) => {
        return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
    };

    const isAudioUrl = (url) => {
        return /\.(mp3|wav|ogg)$/.test(url);
    };

    const photos = [
        'anna1.png', 'anna2.png', 'anna3.png', 
        'anna4.png', 'anna5.png', 'anna6.png',
        'anna7.png', 'anna8.png', 'anna9.png', 
    ];

    const [isModalOpen, setIsModalOpen] = useState(false);

    const Modal = ({ onClose }) => {
        return (
          <div className="backdrop" onClick={onClose}>
            <div className="modal" onClick={e => e.stopPropagation()}>
            <div style={{position:"absolute", top:"5px", right:"5px", cursor:"pointer"}} onClick={onClose}><img src="closeicon.png" width="24px" height="24px"/></div>
            <div className="modelProfile">
                <img key={model?.profile_photo_url} src={modelData?.profile_photo_url  + "?v=2"} height="100px" width="auto"/>
                <div className="modelName">{modelData?.name}</div>
                <div className="followerCount">{modelData?.followers} followers</div>
                {modelData?.instagram ? (
                  <a
                    href={modelData?.instagram}
                    target="_blank"
                    className="instagramFollow"
                    style={{ marginBottom: "50px" }}
                  >
                    <img src="instagram.png" height="20px" /> Instagram
                  </a>
                ) : (
                  <a
                    onClick={() => sweety("Sorry, I'm not on instagram yet.")}
                    target="_blank"
                    className="instagramFollow"
                    style={{ marginBottom: "50px" }}
                  >
                    <img src="instagram.png" height="20px" /> Instagram
                  </a>
                )}
            </div>
            <div className="photo-grid">
                {modelData?.gallery_photos?.map((photo, index) => (
                    <img key={index} src={photo} alt={`Photo ${index + 1}`} className="photo-item" onClick={()=>openImageModal(photo)}/>
                ))}
            </div>
            </div>
          </div>
        );
      };

      const [fullScreenUrl, setFullScreenUrl] = useState('');

      const [isImageModalOpen, setIsImageModalOpen] = useState(false);
      const openImageModal = (url) => {
        setFullScreenUrl(url);
        setIsImageModalOpen(true);
      }
      const closeImageModal = () => {
        setFullScreenUrl('');
        setIsImageModalOpen(false);
      }

      const ImageModal = ({ onClose }) => {
          return (
            <div className="backdrop" onClick={onClose}>
              <div className="modal" onClick={e => e.stopPropagation()}>
                <img src={fullScreenUrl} alt="Full Screen" style={{ width: 'auto', maxHeight: '80vh', maxWidth: '90vw', borderRadius:"20px"}} />
            </div>
            </div>
          );
        };

        const [open, setOpen] = React.useState(false);

        const toggleDrawer = (inOpen) => (event) => {
            if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
              return;
            }
        
            setOpen(inOpen);
          };

    return (
      <>
        <div className="chatContainer">
        <SideNav 
          user={user}
          credits={credits}
          openPaymentModal={openPaymentModal}
          setIsSignupModalOpen={setIsSignupModalOpen}
          setIsFeedbackModalOpen={setIsFeedbackModalOpen}  // Add this line
          signOutUser={signOutUser}
        />
          <div className="panelContainer">
            <div className="panelContents">
              <div className="brandChat">
                Lolli<span style={{ color: "#F339CA" }}>pop</span>
              </div>

              <div className="panelTitle">
                <div>Your contacts</div>
              </div>
              <div className="modelListDiv">
                {models?.map((model, index) => (
                  <div
                    className="menuChatCard"
                    style={{ opacity: model?.key == modelData?.key ? 1 : 0.7 }}
                    onClick={() => model?.locked & !user ? sweety("Log in to view this premium girl.") : selectModel(model?.key)}
                  >
                    <img
                      src={model?.profile_photo_url  + "?v=2"}
                      key={model?.profile_photo_url}
                      height="50px"
                      width="auto"
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="menuChatCardName">
                        {model?.name}{" "}
                        {model?.instagram ? (
                          <img
                            src="verified.png"
                            width="16pc"
                            title="Model has instagram."
                          />
                        ) : null}
                      </div>
                      <div className="menuChatCardDescription">
                        {model?.locked & !user ? "Log in to view." : model?.short_description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="chatHeader">
            <img
              className="hovernoselect"
              src={modelData?.profile_photo_url  + "?v=2"}
              key={model?.profile_photo_url}
              height="50%"
              width="auto"
              onClick={() => setIsModalOpen(true)}
            />
            <div className="hovernoselect" onClick={() => setIsModalOpen(true)}>
              {modelData?.name}
            </div>
            <div className="allContactsMobile" onClick={toggleDrawer(true)}>
              <img src="phone.png" width="16px" />
              All contacts
            </div>
            {/*<div className="toggleTheme" onClick={toggleTheme}>
                    <img src={theme === 'light' ? 'moon.png' : 'sun.png'} width="24px"/>
                </div>*/}
            <div className="header-right">
              {user?.email == "asynchronousdevelopers@gmail.com" && modelData?.name == "Anna Maria" ? (
                            <a className="getNotified" onClick={() => setIsCallingModalOpen(true)}>
                            <img height="16px" src="callicon.png" style={{marginRight:"3px"}}/> Call Anna Maria
                          </a>
              ) : null}

              <a className="getNotified" href="/generate">
                Generate any photos!
              </a>
            </div>
          </div>
          <div className="chatApp">
            <div className="chatContent">
              <div className="modelProfile">
                <img
                  onClick={() => setIsModalOpen(true)}
                  src={modelData?.profile_photo_url  + "?v=2"}
                  key={model?.profile_photo_url}
                  height="100px"
                  width="auto"
                />
                <div onClick={() => setIsModalOpen(true)} className="modelName">
                  {modelData?.name}
                </div>
                <div
                  onClick={() => setIsModalOpen(true)}
                  className="followerCount"
                >
                  {modelData?.followers} followers
                </div>
                <div
                    onClick={() => setEncryptedModalOpen(true)}
                    className="encryptedsecure"
                    style={{ marginBottom: "50px" }}
                  >
                    <img src="icons/encryptedicon.svg" height="20px" /> Encrypted & Secure Chat
                  </div>
              </div>

              <div
                style={{
                  display:
                    bottomRef.current &&
                    bottomRef.current.getBoundingClientRect().top - 300 <=
                      window.innerHeight
                      ? "none"
                      : "fixed",
                }}
                id={messages?.length}
                className="scrollToBottom"
                onClick={() => scrollToBottom()}
              >
                <img src="./scrollDown.png" width="20px" /> Go
              </div>

              {!isNearBottom && (
                <div
                  className="scrollToBottom"
                  onClick={() =>
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" })
                  }
                >
                  <img src="./scrollDown.png" width="20px" /> Go down
                </div>
              )}
              {isSignupModalOpen && (
  <SignupModal 
    onClose={() => setIsSignupModalOpen(false)}
    onSubmit={handleSignupSubmit}
  />
)}
{modelData ? (
  <>
    {modelData &&
    modelData?.name &&
    messages[modelData?.name]?.length > 0
      ? messages[modelData?.name]?.map((msg, index) => (
          <>
            <div
              key={index}
              className="message"
              style={{
                alignSelf:
                  msg.role === "user" ? "flex-end" : "flex-start",
                background:
                  msg.role === "system" && isImageUrl(msg.content)
                    ? "none"
                    : msg.role === "user"
                    ? "#2A8BD4"
                    : "#EFEFEF",
                color: msg.role === "user" ? "white" : "black",
                position: "relative",  // Added for timestamp positioning
              }}
            >
              {msg.role == "system" && isImageUrl(msg.content) ? (
                <div className="imageContainer">
                  {msg?.tag == "upscaled" ||
                  msg?.content == "greeting.png" ? null : (
                    <div
                      hidden={
                        modelData?.key == "chat" &&
                        msg.content !== "annamariagreeting.png"
                          ? false
                          : true
                      }
                      onClick={() => paid ? improveImage(msg.content) : sweety("Upgrade to any plan to make and upscale photos.")}
                      className="improveimage"
                    >
                      Improve photo(2 tokens)
                    </div>
                  )}{" "}
                  {msg?.tag == "upscaled" ? (
                    <div
                      className="upscaledimage"
                      onClick={() => openImageModal(msg.content)}
                    >
                      Improved photo. <br />
                      Click to open!
                    </div>
                  ) : (
                    <ImageWithLoader index={index} src={msg.content} paid={paid} openImageModal={openImageModal} sweety={sweety} openPaymentModal={openPaymentModal}/>
                  )}
                </div>
              ) : msg.role == "audio" ? (
                <audio
                  src={msg.content}
                  controls
                  controlsList="nodownload noplaybackrate "
                />
              ) : (
                <p style={{textAlign: msg.role == "user" ? "right" : "left"}}>
                  {msg?.content?.split(/(\*\*[^*]+\*\*)/).map(
                    (part, idx) =>
                      part.startsWith("**") &&
                      part.endsWith("**") ? (
                        <strong key={idx}>
                          {part.slice(2, -2)}
                        </strong>
                      ) : (
                        part.replace(/"/g, "")
                      )
                  )}
                </p>
              )}
              {msg?.timestamp && (
                <small
                  style={{right: msg.role == "system" ? "25px" : "10px"}}
                  className="message-timestamp"
                >
                  {msg.timestamp == "Just now" ? "Just now" : new Date(msg.timestamp).toLocaleTimeString([], { 
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true 
                  })}
                </small>
              )}
            </div>
          </>
        ))
      : null}
  </>
) : (
  <></>
)}
              {messageLoadingBubbles?.[modelData?.name] ? (
                <div
                  className="message"
                  style={{ alignSelf: "flex-start", background: "#EFEFEF" }}
                >
                  <div className="loading">
                    <span className="loading__dot"></span>
                    <span className="loading__dot"></span>
                    <span className="loading__dot"></span>
                  </div>
                </div>
              ) : null}
              <div className="inputArea">
                <input
                  type="text"
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                  className="inputField"
                  onKeyPress={handleKeyPress}
                  placeholder="Message..."
                />

                <div onClick={() => sendMessage()}>
                  <img className="submitlogo" src="send.png" width="24px" />
                </div>
              </div>
              <span className="bottomRef" ref={bottomRef}></span>
            </div>
          </div>

          {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
          {isImageModalOpen && <ImageModal onClose={() => closeImageModal()} />}
          {isPaymentModalOpen && (
            <PaymentModal 
              onClose={() => setIsPaymentModalOpen(false)}
              user={user}
              endpoint={endpoint}
              sweety={sweety}  // Make sure sweety is defined in the parent component
              isSubscribed={isSubscribed}
              subscriptionWithStripe={(priceId) => subscriptionWithStripe(priceId, user, endpoint, sweety)}
              paymentWithStripe={(priceId) => paymentWithStripe(priceId, user, endpoint, sweety)}
            />
          )}
          {isTosModalOpen && (
            <TosModal onClose={() => setIsTosModalOpen(false)} />
          )}
          {isFeedbackModalOpen && (
            <FeedbackModal onClose={() => setIsFeedbackModalOpen(false)} />
          )}
          {isSponsorModalOpen && (
            <SponsorModal onClose={() => setIsSponsorModalOpen(false)} />
          )}
          {isCallingModalOpen && (
            <CallingModal onClose={() => setIsCallingModalOpen(false)} />
          )}
          {isEncryptedModalOpen && (
  <EncryptedModal onClose={() => setEncryptedModalOpen(false)} />
)}
          {isUnsubscribeModalOpen && (
  <UnsubscribeModal onClose={() => setIsUnsubscribeModalOpen(false)} />
)}
<Drawer
  open={open}
  onClose={toggleDrawer(false)}
  slotProps={{
    content: {
      sx: {
        width: "80vw",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        overflowX: "hidden",
      },
    },
  }}
>
  <div className="drawer-container">
    {/* Scrollable Contacts Section */}
    <div className="drawer-contacts">
      <div className="brandChat">
        Lolli<span style={{ color: "#F339CA" }}>pop</span>
      </div>

      {models?.map((model, index) => (
        <div
          key={index}
          className="menuChatCard"
          style={{ opacity: model?.key == modelData?.key ? 1 : 0.7 }}
          onClick={() => selectModel(model?.key)}
        >
          <img
            src={model?.profile_photo_url + "?v=2"}
            key={model?.profile_photo_url}
            height="50px"
            width="auto"
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="menuChatCardName">{model?.name}</div>
            <div className="menuChatCardDescription">
              {model?.short_description}
            </div>
          </div>
        </div>
      ))}
    </div>

    {/* Fixed Menu Section */}
{/* Replace your existing drawer menu div with this structure */}
<div className="drawer-menu">
  {/* Base grid - always visible */}
  <div className="drawer-menu-grid">
    {user ? (
      <>
<div 
  className="drawer-menu-item"
  onClick={openPaymentModal}
>
  <div className="drawer-menu-label">Credits</div>
  <div className="drawer-menu-value">{credits > 0 ? credits : "Buy"}</div>
</div>

        <div 
          className="drawer-menu-item"
          onClick={signOutUser}
        >
          <div className="drawer-menu-label">Account</div>
          <div className="drawer-menu-value">Log Out</div>
        </div>
      </>
    ) : (
      <div 
        className="drawer-menu-item drawer-menu-more"
        onClick={() => setIsSignupModalOpen(true)}
        style={{ gridColumn: 'span 2' }}
      >
        <div className="drawer-menu-label">Talk to girls</div>
        <div className="drawer-menu-value">Log In</div>
      </div>
    )}
  </div>

  {/* Expandable grid - shown when More is clicked */}
  <div className={`drawer-menu-expanded-grid ${isExpanded ? 'show' : ''}`}>
    {user ? (
      <>
        <a href="/generate" className="drawer-menu-item">
          <div className="drawer-menu-label">Generate</div>
          <div className="drawer-menu-value">Photos</div>
        </a>

        <div 
          className="drawer-menu-item"
          onClick={() => window.open('https://lollipop.chat/partners', '_blank')}
        >
          <div className="drawer-menu-label">Explore</div>
          <div className="drawer-menu-value">Our partners</div>
        </div>

        <a 
          href="mailto:lenny@lollipop.chat"
          className="drawer-menu-item"
        >
          <div className="drawer-menu-label">Get in</div>
          <div className="drawer-menu-value">Contact</div>
        </a>

        <div 
          className="drawer-menu-item"
          onClick={() => setIsFeedbackModalOpen(true)}
        >
          <div className="drawer-menu-label">Send feedback</div>
          <div className="drawer-menu-value">Get tokens</div>
        </div>

        <a 
          href="https://billing.stripe.com/p/login/6oE7sC4UZ6DLcUg5kk"
          target="_blank"
          rel="noopener noreferrer"
          className="drawer-menu-item"
          >
          <div className="drawer-menu-label">Manage</div>
          <div className="drawer-menu-value">Subscription</div>
          </a>

          <a 
          href="/affiliate"
          target="_blank"
          rel="noopener noreferrer"
          className="drawer-menu-item"
          >
          <div className="drawer-menu-label">Become an</div>
          <div className="drawer-menu-value">Affiliate</div>
          </a>
      </>
    ) : (
      <>
        <a href="/generate" className="drawer-menu-item">
          <div className="drawer-menu-label">Generate</div>
          <div className="drawer-menu-value">Photos</div>
        </a>

        <div 
          className="drawer-menu-item"
          onClick={() => window.open('https://lollipop.chat/affiliate', '_blank')}
        >
          <div className="drawer-menu-label">Explore</div>
          <div className="drawer-menu-value">Partners</div>
        </div>

        <a 
          href="mailto:lenny@lollipop.chat"
          className="drawer-menu-item"
          style={{ gridColumn: 'span 2' }}
        >
          <div className="drawer-menu-label">Get in</div>
          <div className="drawer-menu-value">Contact</div>
        </a>
      </>
    )}
  </div>

  {/* More/Less button */}
  <div 
    className="drawer-menu-grid"
    style={{ marginBottom: 0 }}
  >
    <div 
      className="drawer-menu-item drawer-menu-more"
      onClick={() => setIsExpanded(!isExpanded)}
      style={{ gridColumn: 'span 2' }}
    >
      <div className="drawer-menu-label">{isExpanded ? 'Close' : 'Show full'}</div>
      <div className="drawer-menu-value">{isExpanded ? 'Menu' : 'Menu'}</div>
    </div>
  </div>
</div>
  </div>
</Drawer>
        </div>
      </>
    );
}

export default Chat;
