import React, { useState, useEffect } from 'react';
import { auth } from './firebase';  // Make sure this path matches your firebase config import
import './Partners.css';

const AddPartnerModal = ({ onClose, onAdd }) => {
  const [partner, setPartner] = useState({
    url: '',
    image: '',
    title: '',
    name: '',
    description: '',
  });
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/add-partner', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password,
          partner
        }),
      });

      if (!response.ok) throw new Error('Failed to add partner');

      onAdd();
      onClose();
    } catch (error) {
      alert('Failed to add partner: ' + error.message);
    }
  };

  return (
    <div className="modal-backdrop" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>Add New Partner</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Admin Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label>URL:</label>
            <input
              type="url"
              value={partner.url}
              onChange={(e) => setPartner({...partner, url: e.target.value})}
              required
            />
          </div>
          <div className="form-group">
            <label>Image URL:</label>
            <input
              type="url"
              value={partner.image}
              onChange={(e) => setPartner({...partner, image: e.target.value})}
              required
            />
          </div>
          <div className="form-group">
            <label>Title:</label>
            <input
              type="text"
              value={partner.title}
              onChange={(e) => setPartner({...partner, title: e.target.value})}
              required
            />
          </div>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              value={partner.name}
              onChange={(e) => setPartner({...partner, name: e.target.value})}
              required
            />
          </div>
          <div className="form-group">
            <label>Description:</label>
            <textarea
              value={partner.description}
              onChange={(e) => setPartner({...partner, description: e.target.value})}
              required
            />
          </div>
          <div className="modal-buttons">
            <button type="submit">Add Partner</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check auth state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.email === 'lenny@lollipop.chat') {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  const fetchPartners = async () => {
    try {
      const response = await fetch('https://lollipopchat-ca692e070ff0.herokuapp.com/get-partners');
      if (!response.ok) {
        throw new Error('Failed to fetch partners');
      }
      const data = await response.json();
      const partnersData = data?.data || [];
      setPartners(Array.isArray(partnersData) ? partnersData : []);
      setLoading(false);
    } catch (err) {
      console.error('Fetch error:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, []);

  if (loading) {
    return (
      <div className="partners-container">
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading partners...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="partners-container">
      <nav className="nav">
        <div className="nav-left">
          <div className="nav-links">
            <a href="/landing">Home</a>
            <a href="#partners">Partners</a>
            <a href="mailto:lenny@lollipop.chat">Contact</a>
            <a href="/affiliate"><b>Affiliate program!</b></a>
          </div>
        </div>
        {isAdmin && (
          <button className="add-partner-button" onClick={() => setShowModal(true)}>
            Add Partner
          </button>
        )}
      </nav>

      <div className="partners-hero-section">
        <div className="partners-hero-content">
          <h1 className="partners-heading">Our Partners</h1>
          <p className="partners-subheading">
            Collaborating with industry leaders to bring you the best experience
          </p>
        </div>
      </div>

      <div className="partners-grid-section">
        <div className="partners-grid">
          {partners.length > 0 ? (
            partners.map((partner, index) => (
              <a
                key={index}
                href={partner.url}
                target="_blank"
                className="partner-card"
              >
                <img 
                  src={partner.image} 
                  alt={partner.name}
                  className="partner-image"
                />
                <div className="partner-content">
                  <h3 className="partner-name">{partner.name}</h3>
                  <p className="partner-title">{partner.title}</p>
                  <p className="partner-description">{partner.description}</p>
                </div>
              </a>
            ))
          ) : (
            <div className="no-partners">
              <p>No partners found</p>
            </div>
          )}
        </div>
      </div>

      <footer className="partners-footer">
        <p>© {new Date().getFullYear()} Lollipop.chat. All rights reserved.</p>
      </footer>

      {showModal && isAdmin && (
        <AddPartnerModal 
          onClose={() => setShowModal(false)}
          onAdd={fetchPartners}
        />
      )}
    </div>
  );
};

export default Partners;