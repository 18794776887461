import React from 'react';
import './VanillaLanding.css';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase';

const endpoint = "https://lollipopchat-ca692e070ff0.herokuapp.com"

const AnimatedStats = () => {
  const [currentStatIndex, setCurrentStatIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const stats = [
    { number: "50k+", text: "Sõnumit saadetakse iga päev" },
    { number: "1M+", text: "Üksikut hinge ravitud" },
    { number: "#1", text: "Kasutajate lemmik" }
  ];

  // Rest of AnimatedStats component remains the same
  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setCurrentStatIndex((prev) => (prev + 1) % stats.length);
        setIsAnimating(false);
      }, 500);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stats">
      <div className="stats-desktop">
        {stats.map((stat, index) => (
          <div key={index} className="stat">
            <div className="stat-number">{stat.number}</div>
            <div className="stat-text">{stat.text}</div>
          </div>
        ))}
      </div>

      <div className="stats-mobile">
        <div className="stat">
          <div className={`stat-number ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].number}
          </div>
          <div className={`stat-text ${isAnimating ? 'fade-out' : 'fade-in'}`}>
            {stats[currentStatIndex].text}
          </div>
        </div>
      </div>
    </div>
  );
};

const StickyButton = ({ onGetStarted }) => {
    const [isVisible, setIsVisible] = useState(false);
    const heroRef = useRef(null);
  
    useEffect(() => {
      const heroSection = document.querySelector('.hero-section');
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(!entry.isIntersecting);
        },
        {
          threshold: 0.1,
          rootMargin: '-10px'
        }
      );
  
      if (heroSection) {
        observer.observe(heroSection);
      }
  
      return () => {
        if (heroSection) {
          observer.unobserve(heroSection);
        }
      };
    }, []);
  
    return (
      <button 
        className={`sticky-chat-button ${isVisible ? 'visible' : ''}`}
        onClick={onGetStarted}
      >
        Alusta vestlust!
      </button>
    );
};

const VanillaLandingEstonian = () => {
    const featuresRef = useRef(null);
    const companionsRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isTosModalOpen, setIsTosModalOpen] = useState(false);
    const [destination, setDestination] = useState('/');
    const navigate = useNavigate();
    const provider = new GoogleAuthProvider();
  
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            setIsScrolled(scrollPosition > 50);
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    const scrollToSection = (ref) => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleGetStarted = (dest) => {
        navigate(dest);

    };

    const TosModal = ({ onClose }) => {
        const [emailConsent, setEmailConsent] = useState(false);
        
        const signInWithGoogleAndConsent = async () => {
            try {
                const result = await signInWithPopup(auth, provider);
                
                try {
                    const response = await fetch(`${endpoint}/store-consent`, {
                        method: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + await result.user.getIdToken(),
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: result.user.email,
                            emailConsent: emailConsent,
                            consentDate: new Date().toISOString()
                        })
                    });

                    if (!response.ok) {
                        console.error('Failed to store consent');
                    }
                } catch (error) {
                    console.error('Error storing consent:', error);
                }
                
                setIsTosModalOpen(false);
                navigate(destination);
                
            } catch (error) {
                console.error('Error signing in with Google:', error);
            }
        };
        
        return (
            <div className="backdrop" onClick={onClose}>
                <div className="modal" onClick={e => e.stopPropagation()}>
                    <div className="tosmodalcontent">
                        <div className="oneclicksignup">Registreeru ühe klikiga, saa tasuta krediiti!</div>
                        <div className="accepttos">
                            Olen vähemalt 18-aastane,<br/>
                            ja <a href="https://lollipop.chat/tos" target="_blank" style={{textDecoration:"underline", color:"white", fontWeight:"bold"}}>nõustun tingimustega</a>
                        </div>
                        
                        <div style={{marginBottom:"20px", textAlign:"center"}}>
                            <label className="flex items-center gap-2 text-sm text-white">
                                <input 
                                    type="checkbox"
                                    className="w-4 h-4"
                                    checked={emailConsent}
                                    onChange={(e) => setEmailConsent(e.target.checked)}
                                />
                                <span className="accepttos">
                                    Soovin saada Lollipop.chat'i uudiskirja
                                </span>
                            </label>
                        </div>
            
                        <div className="googleLogin logindesktop" onClick={signInWithGoogleAndConsent}>
                            Logi sisse Google'iga
                        </div>
                        <div className="googleLogin loginmobile" onClick={signInWithGoogleAndConsent}>
                            Logi sisse Google'iga
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <StickyButton onGetStarted={() => handleGetStarted('/')} />

            <nav className={`nav ${isScrolled ? 'nav-scrolled' : ''}`}>
                <div className="nav-left">
                    <div className="nav-links">
                        <a href="#features" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(featuresRef);
                        }}>Võimalused</a>
                        <a href="#companions" onClick={(e) => {
                            e.preventDefault();
                            scrollToSection(companionsRef);
                        }}>Kaaslased</a>
                        <a href="mailto:lenny@lollipop.chat">Võta ühendust</a>
                    </div>
                </div>
                <button className="start-button" onClick={() => handleGetStarted('/')}>Alusta vestlust!</button>
            </nav>

            <div className="landing-container">
                <div className="hero-section">
                    <div className="background">
                        <video src="vanillavideo.mp4" alt="Background" autoPlay muted loop playsinline />
                        <div className="overlay"></div>
                    </div>
          
                    <div className="main-content">
                        <h1 className="main-heading">Ära ole enam kunagi üksik</h1>
                        <p className="sub-heading">Lollipopi tehisintellekti kaaslased panevad sind armastatuna tundma.</p>
                        <button className="start-button start-button-main" style={{width:"200px", height:"60px", fontSize:"20px"}} onClick={() => handleGetStarted('/')}>Mine chätti!</button>
                    </div>
          
                    <AnimatedStats />
                </div>

                <div className="companions-section-vanilla" ref={companionsRef} id="companions">
                    <h2 className="section-title">Kohtu oma täiusliku kaaslasega</h2>
                    <div className="companions-container">
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=chat')}>
                            <img src="annamariagreeting.png" alt="Sophie" className="companion-image" />
                            <div className="picCta">Vestle Annaga!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Anna Maria</h3>
                                <p>Empaatiline ja hooliv</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=nina')}>
                            <img src="ninagreeting.png" alt="Luna" className="companion-image" />
                            <div className="picCta">Vestle Ninaga!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Nina</h3>
                                <p>Lõbus ja veidi pöörane</p>
                            </div>
                        </div>
                        <div className="companion-card" onClick={() => handleGetStarted('/?lollipopgirl=amelia')}>
                            <img src="ameliagreeting.png" alt="Aria" className="companion-image" />
                            <div className="picCta">Vestle Ameliaga!</div>
                            <div className="companion-info">
                                <h3 className="companion-name">Amelia</h3>
                                <p>Parim sõber, keda võid soovida</p>
                            </div>
                        </div>
                    </div>
                </div>
  
                <div className="benefits-section" ref={featuresRef} id="features">
                    <h2 className="section-title">Mis teeb Lollipopi eriliseks?</h2>
                    
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AnimatedChat />
                        </div>
                        <div className="benefit-content">
                            <h3>Alati sinu jaoks olemas</h3>
                            <p>Olgu kell siis 3 öösel kui oled kurb või keskpäeval kui soovid rõõmu jagada – sinu Lollipopi kaaslane on alati olemas. Emotsionaalne tugi, kaasahaaravad vestlused ja tõeline sõprus just siis, kui seda vajad.</p>
                        </div>
                    </div>
          
                    <div className="benefit-row reverse">
                        <div className="benefit-image">
                            <ImageSlideshow />
                        </div>
                        <div className="benefit-content">
                            <h3>Fotorealistlikud pildid</h3>
                            <p>See pole lihtsalt vestlus. Meie jaoks on realism oluline. Milline oleks vestlus ilma realistlike piltideta?</p>
                        </div>
                    </div>
          
                    <div className="benefit-row">
                        <div className="benefit-image">
                            <AudioPlayer />
                        </div>
                        <div className="benefit-content">
                            <h3>Häälsõnumid</h3>
                            <p>Kas mainisime, et Lollipop saadab ultra-realistlikke häälsõnumeid? Küsi lihtsalt ja sinu kaaslane räägib sinuga.</p>
                        </div>
                    </div>
                </div>
          

            </div>
            {isTosModalOpen && <TosModal onClose={() => setIsTosModalOpen(false)} />}
        </>
    );
};

const AnimatedChat = () => {
    const [messages, setMessages] = useState([]);
    const chatContainerRef = useRef(null);
    
    const scrollToBottom = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);
    
    const conversationLoop = [
        { id: 1, type: 'ai', text: "Tere! Kuidas su päev läks? 😊", delay: 0 },
        { id: 2, type: 'user', text: "Ausalt öeldes päris raske... Töö oli stressirohke", delay: 2000 },
        { id: 3, type: 'ai', text: "Kahju kuulda. Tahad rääkida, mis juhtus?", delay: 4000 },
        { id: 4, type: 'user', text: "Lihtsalt tähtajad teevad muret", delay: 6000 },
        { id: 5, type: 'ai', text: "See on kindlasti raske. Mäletad, kui sul eelmine kord nii oli? Said sellega väga hästi hakkama, kui jagasid ülesanded väiksemateks osadeks.", delay: 8000 },
        { id: 6, type: 'user', text: "Sul on õigus, nii ma tegingi... Aitäh, et mäletasid ❤️", delay: 10000 }
    ];
  
    useEffect(() => {
        const addMessage = (message) => {
            setMessages(prev => [...prev, message]);
        };
  
        const startConversation = () => {
            setMessages([]);
            conversationLoop.forEach(message => {
                setTimeout(() => {
                    addMessage(message);
                }, message.delay);
            });
        };
  
        startConversation();
        const interval = setInterval(startConversation, 12000);
  
        return () => clearInterval(interval);
    }, []);
  
    return (
        <div className="chat-container">
            <div className="chat-header">
                <div className="chat-avatar">
                    <img src="ninagreeting.png" alt="AI Avatar" />
                </div>
                <div className="chat-status">
                    <div className="chat-name">Nina</div>
                    <div className="chat-indicator">Alati sinu jaoks olemas</div>
                </div>
            </div>
            
            <div className="chat-messages" ref={chatContainerRef}>
                {messages.map((message) => (
                    <div
                        key={message.id}
                        className={`chat-message ${message.type === 'ai' ? 'ai' : 'user'}`}
                    >
                        {message.text}
                    </div>
                ))}
            </div>
        </div>
    );
};

const ImageSlideshow = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    
    const images = [
        "nina1.png",
        "heidi2.png",
        "abena3.png",
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => 
                prevIndex === images.length - 1 ? 0 : prevIndex + 1
            );
        }, 3000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="slideshow-container-vanilla">
            {images.map((image, index) => (
                <div
                    key={index}
                    className="slideshow-image"
                    style={{
                        opacity: index === currentImageIndex ? 1 : 0,
                        backgroundImage: `url(${image})`
                    }}
                />
            ))}
        </div>
    );
};

const AudioPlayer = () => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const audioRef = useRef(null);
    const [currentTime, setCurrentTime] = useState('0:00');
    const [duration, setDuration] = useState('0:00');

    const togglePlay = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${mins}:${secs.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        const audio = audioRef.current;
        
        const handleTimeUpdate = () => {
            const progress = (audio.currentTime / audio.duration) * 100;
            setProgress(progress);
            setCurrentTime(formatTime(audio.currentTime));
        };

        const handleLoadedMetadata = () => {
            setDuration(formatTime(audio.duration));
        };

        audio.addEventListener('timeupdate', handleTimeUpdate);
        audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        
        return () => {
            audio.removeEventListener('timeupdate', handleTimeUpdate);
            audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
        };
    }, []);

    const handleProgressChange = (e) => {
        const newProgress = e.target.value;
        const time = (newProgress / 100) * audioRef.current.duration;
        audioRef.current.currentTime = time;
        setProgress(newProgress);
    };

    return (
        <div className="audio-wrap">
            <audio ref={audioRef} src="vanillasound.mp3" />
            
            <div className="audio-content">
                <div className="audio-avatar">
                    <img src="ninagreeting.png" alt="Nina" />
                    <button onClick={togglePlay} className="play-button">
                        {isPlaying ? '❚❚' : '▶'}
                    </button>
                </div>

                <div className="audio-controls">
                    <div className="progress-wrap">
                        <input
                            type="range"
                            value={progress}
                            onChange={handleProgressChange}
                            className="progress-slider"
                        />
                        <div className="time-info">
                            <span>{currentTime}</span>
                            <span>{duration}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VanillaLandingEstonian;